<template>
    <div>
        <div class="stats-header">
            <span>Liquidity Pool (USD)</span>
        </div>
        <div class="stats-content">
            <div class="stats-chart">
                <div>
                    <canvas id="liquidityPoolChart"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
    name: "LiquidityPool",
    props: {
        pair: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            default: null
        }
    },
    data() {
        return {

        };
    },
    mounted() {
        if (this.stats) {
            this.drawChart();
        }
    },
    methods: {
        formatTimestamp(timestamp) {
            const [date, time] = timestamp.split('T');
            const [hour, minute] = time.split(':');
            return `${date} ${hour}:${minute}`;
        },
        generateChartData() {
            const labels = this.stats.map(stat => this.formatTimestamp(stat.created_at));
            const basePooled = this.stats.map(stat => stat.value.extra.base);
            const quotePooled = this.stats.map(stat => stat.value.extra.quote);

            const quoteUSDPrice = this.stats.map(stat => stat.value.extra.quote_usd_price)
            const baseUSDPrice = this.stats.map(stat => stat.value.extra.quote / stat.value.extra.base * stat.value.extra.quote_usd_price)

            return {
                labels: labels,
                datasets: [
                    {
                        label: `${this.pair.quote_token.symbol} USD`,
                        type: 'line',
                        yAxisID: 'quoteUSD-Y',
                        borderColor: '#F26430',
                        data: quoteUSDPrice,
                    },
                    {
                        label: `${this.pair.base_token.symbol} USD`,
                        type: 'line',
                        yAxisID: 'baseUSD-Y',
                        borderColor: '#009B72',
                        data: baseUSDPrice,
                    },
                    {
                        label: this.pair.base_token.symbol,
                        backgroundColor: '#3587A4',
                        data: basePooled
                    },
                    {
                        label: this.pair.quote_token.symbol,
                        backgroundColor: '#88CCF1',
                        data: quotePooled
                    },
                ]
            };
        },
        drawChart() {
            const data = this.generateChartData()

            const location = document.getElementById('liquidityPoolChart');
            const ctx = location.getContext('2d');
            new Chart(ctx, {
                type: 'bar',
                data: data,
                options: {
                    plugins: {
                        legend: { display: false },
                    },
                    responsive: true,
                    scales: {
                        x: { stacked: true },
                        y: { stacked: true },
                        'quoteUSD-Y': {
                            type: 'linear',
                            position: 'right',
                            // title: {
                            //     display: true,
                            //     text: `${this.pair.quote_token.symbol} USD`
                            // },
                        },
                        'baseUSD-Y': {
                            type: 'linear',
                            position: 'right',
                            // title: {
                            //     display: true,
                            //     text: `${this.pair.base_token.symbol} USD`
                            // },
                        }
                    }
                }
            });
        },
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
};
</script>
<style scoped></style>
