<template>
    <div class="strategy-settings-content">
        <div class="load-cell">
            <p>Slots load</p>
            <div>
                <span>{{ settings.slotLoad }}%</span>
                <input type="range" v-model="settings.slotLoad" min="0" max="100" />    
            </div>
        </div>
        <div class="load-cell" :class="{ 'disabled': settings.slotLoad == 0 }">
            <p>Volume load</p>
            <div>
                <span class="load-value">{{ settings.volumeLoad }}%</span>
                <input type="range" v-model="settings.volumeLoad" min="1" max="100"
                :disabled="settings.slotLoad === 0" />
            </div>
        </div>
        <div class="load-cell info">
            <p>Tokens needed</p>
            <div>
                <span>{{ tokensNeeded.base.toFixed(3) }} {{ " " + pair.base_token.symbol }}</span>
                <span>{{ tokensNeeded.quote.toFixed(3) }} {{ " " +  pair.quote_token.symbol }}</span>
            </div>
        </div>
        <div class="load-cell info">
            <p>Trades fee</p>
            <div>
                <span>{{ toSpendETH.toFixed(3) }} {{ " " + pair.swapper.network.native_symbol }}</span>
                <span>{{ (toGenerate * pair.swapper.config.fee).toFixed(3) }} {{ " " + pair.base_token.symbol }}</span>
            </div>
        </div>
        <div class="load-cell info">
            <p>To generate</p>
            <div>
                <span>{{ toGenerate.toFixed(3) }} {{ " " + pair.base_token.symbol }}</span>
            </div>
        </div>
        <div class="load-cell info">
            <p>Trades amount</p>
            <div>
                <span>{{ totalTrades }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "StrategySettings",
    emits: ['configured'],
    props: {
        chartValues: {
            type: Object,
            required: true,
        },
        timeframe: {
            type: String,
            required: true
        },
        pair: {
            type: Object,
            required: true,
        },
        reserves: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            settings: {
                slotLoad: "10",
                volumeLoad: "10",
            }
        }
    },
    computed: {
        totalTrades() {
            const slotLoad = this.settings.slotLoad / 100
            const tradesPerCandle = Math.floor(this.timeframeInSeconds(this.timeframe) / this.pair.swapper.network.config.txn_time * slotLoad)
            if (tradesPerCandle <= 3) {
                return 3 * this.chartValues.length
            }
            return tradesPerCandle * this.chartValues.length
        },
        tokensNeeded() {
            return this.calculateInitialBalances();
        },
        toGenerate() {
            return this.calculateTotalVolume()
        },
        toSpendETH() {
            return this.pair.swapper.config.swap_gas_price_avg * this.totalTrades / (10 ** 18)
        },
        config() {
            return {
                slotsLoad: this.settings.slotLoad / 100,
                volumeLoad: this.settings.volumeLoad / 100,
            }
        }
    },
    created() {
        this.$emit('configured', this.config)
    },
    watch: {
        config(newVal) {
            this.$emit('configured', newVal)
        }
    },
    methods: {
        roundTo3(num) {
            return parseInt(num * 1000) / 1000;
        },
        timeframeInSeconds(value) {
            const unit = value[value.length - 1];
            const amount = parseInt(value.slice(0, -1), 10);
            switch (unit) {
                case 'm':
                    return amount * 60;
                case 'h':
                    return amount * 60 * 60;
                default:
                    return 0;
            }
        },
        calculateInitialBalances() {
            let baseBalance = 0;
            let quoteBalance = 0;
            let maxBorrowedBase = 0
            let maxBorrowedQuote = 0

            let basePooled = this.reserves[0];
            let quotePooled = this.reserves[1];

            for (let i = 0; i < this.chartValues.length; i++) {
                for (const action of ['high', 'low', 'close']) {
                    const targetPrice = this.chartValues[i][action];
                    const [newBasePoolDelta, newQuotePoolDelta] = this.calculatePoolChangesAmounts(basePooled, quotePooled, targetPrice);

                    const baseNeeded = Math.abs(newBasePoolDelta);
                    const quoteNeeded = Math.abs(newQuotePoolDelta);

                    basePooled += newBasePoolDelta;
                    quotePooled += newQuotePoolDelta;

                    if (newQuotePoolDelta > 0) {
                        if (quoteNeeded > quoteBalance) {
                            const quoteBorrowed = quoteNeeded - quoteBalance;
                            quoteBalance += quoteBorrowed;
                            maxBorrowedQuote += quoteBorrowed;
                        }

                    } else {
                        if (baseNeeded > baseBalance) {
                            const baseBorrowed = baseNeeded - baseBalance;
                            baseBalance += baseBorrowed;
                            maxBorrowedBase += baseBorrowed
                        }
                    }
                    baseBalance -= newBasePoolDelta;
                    quoteBalance -= newQuotePoolDelta;
                }
            }
            return {base: maxBorrowedBase, quote: maxBorrowedQuote}
        },
        calculatePoolChangesAmounts(tokensPooled, assetsPooled, targetPrice) {
            const invariant = tokensPooled * assetsPooled;
            const newTokensPooled = Math.sqrt(invariant / targetPrice);
            const newAssetsPooled = targetPrice * newTokensPooled;

            const tokensToSwap = newTokensPooled - tokensPooled;
            const assetsToSwap = newAssetsPooled - assetsPooled;

            return [tokensToSwap, assetsToSwap];
        },
        calculateTotalVolume() {
            let currentTokensPooled = this.reserves[0];
            let currentAssetsPooled = this.reserves[1];
            let totalTokenVolume = 0;

            const slotLoad = this.settings.slotLoad / 100
            const volumeLoad = this.settings.volumeLoad / 100

            for (const ohlc of this.chartValues) {
                if (slotLoad > 0) {
                    let [tempTokensPooled, tempAssetsPooled] = [currentTokensPooled, currentAssetsPooled]
                    const [tempHighDeltaTokens, tempHighDeltaAssets] = this.calculatePoolChangesAmounts(tempTokensPooled, tempAssetsPooled, ohlc.high)
                    tempTokensPooled += tempHighDeltaTokens
                    tempAssetsPooled += tempHighDeltaAssets
                    const [tempLowDeltaTokens,] = this.calculatePoolChangesAmounts(tempTokensPooled, tempAssetsPooled, ohlc.low)
                    totalTokenVolume += (this.totalTrades - 3) * Math.abs(tempLowDeltaTokens) * volumeLoad
                }
                const [highTokenPoolDelta, highAssetPoolDelta] = this.calculatePoolChangesAmounts(currentTokensPooled, currentAssetsPooled, ohlc.high)
                totalTokenVolume += Math.abs(highTokenPoolDelta);
                currentTokensPooled += highTokenPoolDelta;
                currentAssetsPooled += highAssetPoolDelta;
                const [lowTokenPoolDelta, lowAssetPoolDelta] = this.calculatePoolChangesAmounts(currentTokensPooled, currentAssetsPooled, ohlc.low)
                totalTokenVolume += Math.abs(lowTokenPoolDelta);
                currentTokensPooled += lowTokenPoolDelta;
                currentAssetsPooled += lowAssetPoolDelta;

                const [closeTokenPoolDelta, closeAssetPoolDelta] = this.calculatePoolChangesAmounts(currentTokensPooled, currentAssetsPooled, ohlc.close)
                totalTokenVolume += Math.abs(closeTokenPoolDelta);
                currentTokensPooled += closeTokenPoolDelta;
                currentAssetsPooled += closeAssetPoolDelta;
            }
            return totalTokenVolume
        },
    }
}
</script>
<style>
.strategy-settings-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.load-cell{
    display: flex;
    align-items: center;
    border: 1px solid #bebebe;
    padding: 10px;
    border-radius: 10px;
    width: 270px;
    justify-content: space-between;
}
.load-cell > p{
    font-size: 12px;
}
.load-cell > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.load-cell > div > span{
    font-size: 12px
}
.load-cell.info {
    background-color: rgb(255, 231, 164);
    border-color:  rgb(255, 231, 164);
}
.load-cell.info > div{
    align-items: flex-end !important;
    font-weight: 700;
}
</style>