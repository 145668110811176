<template>
    <ModalWindow :title="'New basic strategy'" @close="$emit('close')" :width="'1400px'">
        <div class="header">
            <input v-model="title" placeholder="Title..." />
        </div>
        <div class="create-basic-strategy-content">
            <ChartSettings @update-settings="setChartSettings" />
            <ChartGenerator v-if="chartSettings" :settings="chartSettings" :initPrice="initPrice"
                @generated="setChartValues" />
            <ChartPreview v-if="chartValues" :timeframe="chartSettings.timeframe" :data="chartValues" />
            <div>
                <StrategySettings v-if="chartValues" :pair="pair" :chartValues="chartValues" :timeframe="chartSettings.timeframe" :reserves="reserves" @configured="setStrategySettings"/>
                <TradersSettings v-if="chartValues" @configured="setTradesSettings"/>
            </div>
        </div>
        <div class="save-row">
            <button @click="save">Save</button>
        </div>
    </ModalWindow>
</template>

<script>
import axios from "axios";

import ModalWindow from "./../../common/Modal.vue"
import ChartPreview from "./Creation/ChartPreview.vue"
import ChartSettings from "./Creation/ChartSettings.vue"
import ChartGenerator from "./Creation/Basic/ChartGenerator/ChartGenerator.vue"
import StrategySettings from "./Creation/Basic/StrategySettings.vue"
import TradersSettings from "./Creation/Basic/TradersSettings.vue"

export default {
    name: "AddBaseStrategy",
    emits: ['close'],
    components: {
        StrategySettings,
        ModalWindow,
        ChartPreview,
        ChartSettings,
        ChartGenerator,
        TradersSettings
    },
    props: {
        pair: {
            type: Object,
            required: true,
        },
        reserves: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            title: null,
            chartSettings: null,
            chartValues: null,
            strategySettings: null,
            tradesSettings: null,
        };
    },
    computed: {
        initPrice() {
            return this.reserves[1] / this.reserves[0]
        }
    },
    methods: {
        setChartSettings(value) {
            this.chartSettings = value;
        },
        setChartValues(value) {
            this.chartValues = value;
        },
        setStrategySettings(value) {
            this.strategySettings = value;
        },
        setTradesSettings(value) {
            this.tradesSettings = value;
        },
        normalizeCandles() {
            let formattedCandles = []
            for (let i = 0; i < this.chartValues.length; i++) {
                formattedCandles.push({
                    open: this.chartValues[i].open / this.initPrice,
                    high: this.chartValues[i].high / this.initPrice,
                    low: this.chartValues[i].low / this.initPrice,
                    close: this.chartValues[i].close / this.initPrice,
                })
            }
            return formattedCandles
        },
        async save() {
            const payload = {
                title: this.title,
                type: "Basic",
                frames_amount: this.chartSettings.framesAmount,
                timeframe: this.chartSettings.timeframe,
                ignore_deviation_low: this.tradesSettings.ignoreDeviationLow,
                ignore_deviation_high: this.tradesSettings.ignoreDeviationHigh,
                volume_load: this.strategySettings.volumeLoad,
                slots_load: this.strategySettings.slotsLoad,
                config: {
                    gas_modifiers: {
                        limit: this.tradesSettings.gasModifiers.gasLimit,
                        price: this.tradesSettings.gasModifiers.gasPrice,
                    },
                    spare_traders_amount: this.tradesSettings.newTradersAmount,
                    spare_traders_balance: this.tradesSettings.newTradersBalance * (10 ** 18),
                    max_swaps_per_trader: this.tradesSettings.maxTraderSwaps,
                },
                candles: this.normalizeCandles(),
            }
            try {
                const url = `pair/${this.pair.id}/strategies`
                await axios.post(url, payload)
                window.location.reload()
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
    },
};
</script>

<style scoped>
input {
    font-family: "Roboto mono", monospace;
    border: none;
}

input[type="range"] {
    width: 150px;
}

select {
    font-family: "Roboto mono", monospace;
    border: none;
    border-bottom: 1px solid black;
}

.header {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.header>input {
    font-size: 24px;
    background-color: #eee;
    padding: 10px;
    border-radius: 10px;
}

.create-basic-strategy-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.create-basic-strategy-content > :last-child{
    display: flex;
    gap: 15px;
    justify-content: center;
}

.devider {
    width: 70%;
    margin-left: 15%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #bdbdbd;
}

.params-container {
    margin-top: 30px;
}

.params-row-title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 40px;
}

.params-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-bottom: 20px;
}

.params-cell {
    display: flex;
    align-items: center;
    gap: 5px;
}

.params-cell.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.params-cell>input[type="number"] {
    width: 40px;
    padding: 0;
    font-size: 16px;
    text-align: right;
    margin-left: 5px;
}

.params-cell>select {
    font-size: 16px;
    text-align: right;
}

.close-btn:hover,
.close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

#generatedChartContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

#generatedChart {
    width: 90%;
    height: 500px;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    padding: 20px;
    display: none;
}

.save-row {
    margin-top: 20px;
    text-align: center;
}

.save-row>button {
    border: none;
    background: none;
    padding: 10px 15px;
    background-color: aqua;
    font-family: 'Romoto Mono', monospace;
    color: black;
    font-size: 20px;
    cursor: pointer;
}

.save-row>button:hover {
    background-color: rgb(164, 255, 255);
}

.info-calculated-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
}

.info-calculated-text {
    padding: 8px 10px;
    border-radius: 10px;
    background-color: antiquewhite;
}

.volume-adjust-header {
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.volume-adjust-header.active {
    color: grey;
    margin-top: 10px;
}

.volume-adjust-header>span:hover {
    text-decoration: underline;
}

.volume-adjust-content {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.volume-adjust-container {
    border: 1px solid black;
    padding: 10px;
}

.params-total-time {
    padding: 5px 10px;
    background-color: #d4d4d4;
    border-radius: 10px;
}

.load-value {
    width: 40px;
    text-align: end;
}
</style>
