<template>
    <HeaderComponent />
    <div class="profile-page">
        <!-- Раздел изменения пароля -->
        <div class="password-change inline-section">
            <div class="cs-header small">Change password</div>
            <div class="password-change-form">
                <input class="cs-input small" :class="{ 'non-empty': passwordSection.old.length > 0 }"
                    v-model="passwordSection.old" type="password" placeholder="Old password" />
                <input class="cs-input small" :class="{ 'non-empty': passwordSection.new.length > 0 }"
                    v-model="passwordSection.new" type="password" placeholder="New password" />
                <input class="cs-input small" :class="{ 'non-empty': passwordSection.confirmNew.length > 0 }"
                    v-model="passwordSection.confirmNew" type="password" placeholder="New password again" />
                <button class="cs-button small" @click="changePassword">Submit</button>
            </div>
        </div>

        <!-- Активные сессии -->
        <div class="sessions inline-section">
            <div class="cs-header small">
                Active Sessions
            </div>
            <div class="sessions-list">
                <div class="session current">
                    <div v-if="userSessionsData.current" class="session-info">
                        <div>
                            <span><b>{{ "Opened in: " + formatDatetime(userSessionsData.current.created_at)
                                    }}</b>,</span>
                            <span>
                                {{
                    userSessionsData.current.agent.browser.family + ", " +
                    userSessionsData.current.agent.device.brand + " " +
                    userSessionsData.current.agent.device.model
                }}
                            </span>
                        </div>
                        <div>
                            <span>
                                {{
                    userSessionsData.current.agent.location.city + ", " +
                    userSessionsData.current.agent.location.country
                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="session-buttons" v-if="userSessionsData.others.length > 0">
                    <button class="cs-button small" @click="closeOtherSessions">Close all other sessions</button>
                </div>
                <div class="session" v-for="session in userSessionsData.others" :key="session.id">
                    <div class="session-info">
                        <div>
                            <span><b>{{ "Opened in: " + formatDatetime(session.created_at) }}</b>,</span>
                            <span>
                                {{
                    session.agent.browser.family + ", " +
                    session.agent.os.family + " " +
                    session.agent.device.model
                }}
                            </span>
                        </div>
                        <div>
                            <span>
                                {{
                    session.agent.location.city + ", " +
                    session.agent.location.country
                                }}
                            </span>
                        </div>
                    </div>
                    <button @click="closeSession(session.id)">Close</button>
                </div>
            </div>
        </div>
        <!-- Двухфакторная аутентификация с Telegram -->
        <div class="telegram inline-section">
            <div class="cs-header small">
                Telegram
            </div>
            <div v-if="!userData.tgId" class="add-twofa-form">
                <p>Go to our
                    <a target="_blank" href="https://t.me/bitfusion_bot?start=start">bot</a>
                    and call
                    <b>/link_profile</b>.
                    Then put received token in lower form
                </p>
                <div class="tg-interface">
                    <input class="cs-input small" v-model="telegramSection.linkToken" placeholder="Enter token" />
                    <button class="cs-button small" @click="verifyTelegramToken">Submit</button>
                </div>
            </div>
            <div v-else>Connected!</div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "../Header.vue";

export default {
    name: "ProfilePage",
    components: { HeaderComponent },
    data() {
        return {
            userData: {
                tgId: null,
                username: null,
                accessLevel: null,
            },
            userSessionsData: {
                current: null,
                others: [],
            },
            passwordSection: {
                old: '',
                new: '',
                confirmNew: '',
            },
            telegramSection: {
                telegramId: '',
                linkToken: '',
            },
        };
    },
    async created() {
        await this.fetchSessionsInfo()
        await this.fetchUserInfo()
    },
    methods: {
        async fetchUserInfo() {
            try {
                const userResponse = await axios.get('/user');
                this.userData.tgId = userResponse.data.tg_id;
                this.userData.username = userResponse.data.username;
                this.userData.accessLevel = userResponse.data.access_level;
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        async fetchSessionsInfo() {
            try {
                const userCurrentSessionResponse = await axios.get('/user-session');
                const userOtherSessionsResponse = await axios.get('/user-session/others');
                this.userSessionsData.current = userCurrentSessionResponse.data;
                this.userSessionsData.others = userOtherSessionsResponse.data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        async changePassword() {
            if (this.passwordSection.new !== this.passwordSection.confirmNew) {
                this.$root.displayError('Passwords do not match');
                return
            }
            try {
                await axios.post('/user/change-password', {
                    old_password: this.passwordSection.old,
                    new_password: this.passwordSection.new,
                })
                this.$root.displaySuccess('Password changed successfully');
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        formatDatetime(dateTimeStr) {
            const dateTime = new Date(dateTimeStr);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const compareDate = new Date(dateTime.toDateString());
            const parsedTime = `${this.padZero(dateTime.getHours())}:${this.padZero(dateTime.getMinutes())}`;
            const parsedDate = `${this.padZero(dateTime.getDate())}.${this.padZero(dateTime.getMonth() + 1)}.${dateTime.getFullYear()}`;

            if (compareDate.getTime() === today.getTime()) {
                return parsedTime
            } else {
                return `${parsedTime} ${parsedDate}`
            }
        },
        padZero(num) {
            return num < 10 ? '0' + num : num;
        },
        async verifyTelegramToken() {
            try {
                await axios.post("/user/link-telegram", { token: this.telegramSection.linkToken })
                this.$root.displaySuccess("Telegram successfully linked")
                await this.fetchUserInfo()
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        async closeOtherSessions() {
            try {
                await axios.post("/user-session/close-others")
                this.$root.displaySuccess('Success');
                await this.fetchSessionsInfo()
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        async closeSession(sessionId) {
            try {
                await axios.post(`/user-session/${sessionId}/close`)
                this.$root.displaySuccess('Success');
                await this.fetchSessionsInfo()
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        }

    },
};
</script>
<style scoped>
h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    margin-bottom: 20px;
}

.profile-page {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 0 5%;
}

.password-change-form {
    display: flex;
    flex-direction: column;
    width: 200px;
    gap: 10px;
}

.password-change-form> :last-child {
    margin-top: 10px;
}

.inline-section {
    border: 1px solid #bebebe;
    display: inline-block;
    padding: 20px;
    border-radius: 20px;
}

.password-change-form>button {
    padding: 5px;
}

.sessions-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 200px;
    overflow-y: scroll;
}

.sessions {
    min-width: 400px;
}

.session {
    padding: 8px;
    border-radius: 4px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.session>button {
    color: #606060;
}

.session.current {
    background-color: #d5ffcb;
}

.session-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #606060;
    font-size: 12px;
}

.session-info>div {
    display: flex;
    gap: 10px;
}

.session-buttons {
    margin-top: 10px;
    display: flex;
    gap: 5px;
}

.telegram {
    max-width: 300px;
    min-width: 150px;
}

.add-twofa-form>p {
    display: block;
    font-size: 12px;
    margin: 0;
    margin-bottom: 10px;
}

.tg-interface {
    display: flex;
    gap: 5px;
}
</style>