<template>
    <div class="cs">
        <div class="cs-header">
            Verify email
        </div>
        <div class="cs-content">
            <span>
                Enter the code sent to the <b>{{ context.email }}</b>
            </span>
            <div class="cs-tools">
                <input class="cs-input" v-model="code" placeholder="Received code">
                <div class="resend-container">
                    <span v-if="waitTime > 0">{{ waitTime }}</span>
                    <button class="cs-button" :disabled="waitTime > 0" @click="sendEmailOTP">Send</button>
                </div>
            </div>
            <div class="cs-tools">
                <button class="cs-button" @click="$emit('back')">Back</button>
                <button class="cs-button next" :disabled="!code" @click="verifyEmailOTP">Verify</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "VerifyEmail",
    emits: ['updContext', 'back', 'next'],
    props: {
        context: {
            type: Object
        }
    },
    data() {
        return {
            code: "",
            waitTime: 0,
            timer: null, 
        }
    },
    async mounted() {
        await this.sendEmailOTP()
    },
    beforeUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        async sendEmailOTP() {
            try {
                const url = `registration/send-email-otp`;
                const { data } = await axios.post(url, {}, { withCredentials: true })
                this.waitTime = data.wait;
                this.startTimer();
            } catch (error) {
                if (error.response.status === 403) {
                    this.$root.displayError("Please wait before next try")
                    return
                }
                this.$root.displayError(error.response.data.detail)
            }
        },
        async verifyEmailOTP() {
            try {
                const url = `registration/verify-email-otp`;
                await axios.post(url, {code: this.code}, { withCredentials: true })
                this.$emit('updContext', {emailConfirmed: true})
                this.$emit('next')
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        startTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.waitTime > 0) {
                    this.waitTime--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        }
    },
}
</script>
<style scoped>
.cs-tools {
    display: flex;
    justify-content: space-between;
}

.cs-tools>input {
    width: 300px;
    margin-left: -2px;
}
.resend-container{
    display: flex;
    gap: 20px;
    align-items: center
}
.resend-container > span{
    color: #a0a0a0;
}
.cs-content > :last-child{
    margin-top: 20px;
}
</style>