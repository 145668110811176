<template>
    <div class="tool-container">
        <div class="tool-header">
            <div class="tool-header-title">Quick Trade</div>
        </div>
        <div class="quick-action-container">
            <div class="quick-action-list">
                <MakeSwap
                :baseToken="pair.base_token"
                :quoteToken="pair.quote_token"
                :reserves="lockedReserves"
                :isActive="subwindowActive.makeSwap"
                @click="activateSubwindow('makeSwap')"
                @tradeReady="makeSwapTradeReady"
                class="quick-action-type"
                />
                <MovePrice
                :baseToken="pair.base_token"
                :quoteToken="pair.quote_token"
                :reserves="lockedReserves"
                :isActive="subwindowActive.movePrice"
                @click="activateSubwindow('movePrice')"
                @tradeReady="movePriceTradeReady"
                class="quick-action-type"
                />
            </div>
            <ProcessContainer
            :tradeData="tradeData"
            :pair="pair"
            />
        </div>

    </div>
</template>

<script>
import MovePrice from "./MovePrice.vue";
import MakeSwap from "./MakeSwap.vue";
import ProcessContainer from "./Process.vue";

export default {
    name: "QuickTrade",
    components: { MovePrice, MakeSwap, ProcessContainer },
    props: {
        pair: {
            type: Object,
            required: true
        },
        reserves: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            lockedReserves: this.reserves,
            subwindowActive: {
                makeSwap: false,   
                movePrice: false,   
            },
            subwindowTradeData: {
                makeSwap: null,   
                movePrice: null,   
            },
            tradeData: null,
        };
    },
    watch: {
        reserves(newVal) {
            if (JSON.stringify(newVal) !== JSON.stringify(this.lockedReserves)) {
                this.lockedReserves = newVal;
            }
        }
    },
    methods: {
        activateSubwindow(subwindowName) {
            Object.keys(this.subwindowActive).forEach(key => {
                this.subwindowActive[key] = false;
            });
            if (!this.subwindowActive[subwindowName]) {
                this.subwindowActive[subwindowName] = true;
                this.tradeData = this.subwindowTradeData[subwindowName];
            }
        },
        makeSwapTradeReady(data) {
            if (this.subwindowActive.makeSwap) {
                this.tradeData = data;
            }
            this.subwindowTradeData.makeSwap = data;
        },
        movePriceTradeReady(data) {
            if (this.subwindowActive.movePrice) {
                this.tradeData = data;
            }
            this.subwindowTradeData.movePrice = data;
        },
    }
}
</script>

<style>

.quick-action-disabled{
    opacity: 0.3;
    cursor: pointer;
}
.quick-action-container{
    display: flex;
    flex-direction: column;
    height: 430px;
    justify-content: space-between;
}
.quick-action-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.quick-action-type {
    border: 1px solid #bebebe;
    padding: 15px;
    border-radius: 5px;
}

.quick-action-header {
    margin-bottom: 5px;
    font-size: 14px;
}

.quick-action-params {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.quick-action-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-end
}

.quick-action-cell>p {
    margin: 0;
    color: rgb(21, 21, 21);
    font-size: 10px;
    padding-right: 3px
}

.error {
    border-color: red !important;
}

.estimation-container {
    display: flex;
    justify-content: center;
}

.estimation {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.estimation>div {
    padding: 3px 6px;
    border-radius: 5px;
    background-color: rgb(255, 247, 203);
    display: flex;
    gap: 5px;
}
.process-container{
    margin-top: auto;
}
</style>
