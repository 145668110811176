<template>
    <div class="content">
        <div>
            Internal server error
        </div>
        <div>
            <div>
                Oops! It looks like something went wrong on our end. 
            </div>
            <div>
                We're working to fix this as quickly as possible.
                We would be grateful if you could email us at <b>claims@bitfusion.trade</b> with the time the error occurred and what actions you were taking before it happened. 
            </div>
            
        </div>
    </div>
</template>
<script>
export default {
    name: "ErrorPage500"
}
</script>
<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: rgb(56, 56, 56);
}
.content > :first-child{
    font-size: 42px;
}
.content > :nth-child(2){
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    max-width: 500px;
}
</style>