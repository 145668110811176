<template>
    <ModalWindow :title="'Confirmation'" @close="$emit('close')">
        <div>
            <span>{{ warning }}</span>
        </div>
        <div class="buttons">
            <button @click="$emit('yes')">Yes</button>
            <button @click="$emit('no')">No</button>
        </div>
    </ModalWindow>
</template>
<script>
import ModalWindow from "./common/Modal.vue"

export default {
    name: "ConfirmationModal",
    components: { ModalWindow },
    emits: ["yes", 'no', 'close'],
    props: {
        warning: {
            type: String,
            required: true
        }
    },
    methods: {
    }
}
</script>
<style scoped>
.buttons{
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
}
.buttons > button {
    padding: 5px 10px;
}
</style>