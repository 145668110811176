<template>
    <div class="content">
        <div>
            Bitfusion is temporary unavailable
        </div>
        <div>
            <div>
                Hey there! 
            </div>
            <div>
                We're temporarily limiting access to our system as we roll out some updates. 
            </div>
            <div>
                We'll be finished in just a couple of minutes, so please hang tight and try again soon.
                We're excited for you to experience the improvements we're making!
            </div>
            <div>
                If you notice this problem persists, please let us know by emailing us at <b>claims@bitfusion.trade</b> with details about the issue
            </div>
            
        </div>
    </div>
</template>
<script>
export default {
    name: "ErrorPage503"
}
</script>
<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: rgb(56, 56, 56);
}
.content > :first-child{
    font-size: 42px;
}
.content > :nth-child(2){
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    max-width: 550px;
}
</style>