<template>
    <div class="stats-header">
        <span>Account Balances</span>
        <div class="stats-header-params">
            <span>[</span>
            <select v-model="selectedOption" @change="drawChart">
                <option value="total-usd">Total (USD)</option>
                <option value="base">Base</option>
                <option value="base-usd">Base (USD)</option>
                <option value="quote">Quote</option>
                <option value="quote-usd">Quote (USD)</option>
                <option value="native">Native</option>
                <option value="native-usd">Native (USD)</option>
            </select>
            <span>]</span>
        </div>
    </div>
    <div class="stats-content">
        <LoadingCirlce v-if="!stats" />
        <div v-else class="stats-chart" id="totalBalanceChart"></div>
    </div>
</template>

<script>
import { createChart } from 'lightweight-charts';
import LoadingCirlce from '../../common/Loader.vue'

export default {
    name: "TotalBalance",
    props: {
        pair: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            default: null
        }
    },
    components: { LoadingCirlce },
    data() {
        return {
            selectedOption: 'total-usd',
        }
    },
    mounted() {
        if (this.stats) {
            this.drawChart();
        }
    },
    methods: {
        drawChart() {
            const chartData = this.prepareData(this.stats, this.selectedOption);
            const location = document.getElementById('totalBalanceChart');
            location.innerHTML = '';

            const chartOptions = {
                layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
                autoSize: true,
                timeScale: {
                    timeVisible: true,
                    fitContent: true,
                },
            };
            const chart = createChart(location, chartOptions);

            const lineSeriesOptions = { lineColor: '#66eb3d', topColor: '#66eb3d', bottomColor: 'rgba(255, 255, 255, 0.2)' }
            chart.addAreaSeries(lineSeriesOptions).setData(chartData);

            chart.timeScale().fitContent();
        },
        prepareData() {
            const calculations = {
                'total-usd': item => (item.base / (10 ** this.pair.base_token.decimals) * item.usd_prices.base) +
                    (item.quote / (10 ** this.pair.quote_token.decimals) * item.usd_prices.quote) +
                    (item.native / (10 ** 18) * item.usd_prices.native),
                'base-usd': item => (item.base / (10 ** this.pair.base_token.decimals) * item.usd_prices.base),
                'quote-usd': item => (item.quote / (10 ** this.pair.quote_token.decimals) * item.usd_prices.quote),
                'native-usd': item => (item.native / (10 ** 18) * item.usd_prices.native),
                'base': item => (item.base / (10 ** this.pair.base_token.decimals)),
                'quote': item => (item.quote / (10 ** this.pair.quote_token.decimals)),
                'native': item => (item.native / (10 ** 18))
            };
            const chartData = this.stats.map((item,) => {
                const date = new Date(item.created_at);
                const utcTimestamp = parseInt(date.getTime() / 1000);
                return {
                    time: utcTimestamp,
                    value: calculations[this.selectedOption](item.value),
                };
            });
            return chartData
        },
    },
};
</script>

<style scoped>
.stats-header-params>select {
    border: none;
    text-align: left;
}
</style>