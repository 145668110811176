<template>
    <ModalWindow :title="'Transfer'" @close="$emit('close')">
    <div class="transfer-container">
        <div class="transfer-option">
            <div class="transfer-option-header">
                <div>
                    <select v-model="manager.action">
                        <option value="deposit">Deposit</option>
                        <option value="withdraw">Withdraw</option>
                    </select>
                    <span>
                        <b>Pair Manager</b>
                    </span>
                </div>
            </div>
            <div class="transfer-option-inputs">
                <div v-if="manager.action === 'withdraw'" class="transfer-is-all-from-manager">
                    <span v-if="!manager.isWithdrawAll">all?</span>
                    <span v-else>all</span>
                    <input type="checkbox" v-model="manager.isWithdrawAll">
                </div>
                <input v-if="!manager.isWithdrawAll" v-model.number="manager.amount.native" type="number" 
                    v-bind:placeholder="pair.swapper.network.native_symbol" />
                <input v-if="!manager.isWithdrawAll" v-model.number="manager.amount.base" type="number" 
                    v-bind:placeholder="pair.base_token.symbol" />
                <input v-if="!manager.isWithdrawAll" v-model.number="manager.amount.quote" type="number" 
                    v-bind:placeholder="pair.quote_token.symbol" />
                <button class="transfer-send-btn" @click="managerTransfer">Send</button>
            </div>

        </div>
        <div v-if="balances.traders.length > 0" class="transfer-option">
            <div class="transfer-option-header">
                <div>
                    <select v-model="trader.action">
                        <option value="deposit">Deposit</option>
                        <option value="withdraw">Withdraw</option>
                    </select>
                    <b>
                        Trader Wallet
                    </b>
                </div>
                <div>
                    <select v-model="trader.id">
                        <option v-for="(wallet) in balances.traders" :key="wallet.id" :value="wallet.id">
                            {{ contractShortener(wallet.address) }}, {{ balanceCorrector(wallet.native_balance, 18) }} {{
                                pair.swapper.network.native_symbol }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="transfer-option-inputs">
                <input v-model.number="trader.amount" type="number"
                    v-bind:placeholder="pair.swapper.network.native_symbol" />
                <button class="transfer-send-btn" @click="traderWalletSend">Send</button>
            </div>
        </div>
    </div>
</ModalWindow>
</template>
<script>
import axios from 'axios';
import ModalWindow from '../../common/Modal.vue'

export default {
    name: "ModalTransfer",
    emits: ['close'],
    components: {ModalWindow},
    props: {
        balances: {
            type: Object,
            required: true
        },
        pair: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            trader: {
                action: "deposit",
                amount: null,
                id: null,
            },
            manager: {
                action: "deposit",
                amount: {
                    native: null,
                    base: null,
                    quote: null,
                },
                isWithdrawAll: false,
            },
        };
    },
    created() {
        if (this.balances.traders.length > 0) {
            this.trader.id = this.balances.traders[0].id
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        async traderWalletSend() {
            if (this.trader.amount === null) {
                this.trader.amount = 0
            }
            try {
                const url = `pair/${this.pair.id}/trader/${this.trader.id}/${this.trader.action}`
                const { data } = await axios.post(url, {
                    native_amount: Math.round(this.trader.amount * (10 ** 18))
                });
                if (data.data) {
                    this.$root.displaySuccess('Transfered successfully')
                } else {
                    this.$root.displayError('Unable to fetch the result of execution');
                }
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        async managerTransfer() {
            let payload;
            let url;

            if (!this.manager.isWithdrawAll) {
                url = `pair/${this.pair.id}/manager/${this.manager.action}`
                payload = {
                    native_amount: this.manager.amount.native,
                    base_amount: this.manager.amount.base,
                    quote_amount: this.manager.amount.quote
                }
            } else {
                url = `pair/${this.pair.id}/manager/withdraw-all`
            }

            try {
                const { data } = await axios.post(url, payload)
                if (data.data) {
                    this.$root.displaySuccess('Transfered successfully')
                } else {
                    this.$root.displayError('Unable to fetch the result of execution');
                }
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        contractShortener(value) {
            return value.substr(0, 4) + ".." + value.substr(-3);
        },
        balanceCorrector(value, decimals) {
            return parseFloat(value) / Math.pow(10, decimals);
        },
    }
}
</script>

<style scoped>
input{
    padding: 5px 10px;
    border: 1px solid #bebebe;
    border-radius: 5px;
    width: 100px;
}
.transfer-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border: 1px solid #bebebe;
    border-radius: 10px;
    padding: 10px;
}

.transfer-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.transfer-option-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    font-size: 16px;
}

.transfer-option-header>div>select {
    border: none;
    padding: 0;
    font-size: 16px;
    font-family: "Roboto Mono", monospace;
    appearance: none;
    text-decoration: underline;
    cursor: pointer;
    width: auto;
    display: inline-block;
    text-align: center;
}

.transfer-is-all-from-manager {
    display: flex;
    align-items: center;
    gap: 1px;
}

.transfer-option-inputs {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.transfer-input {
    padding: 3px 5px;
    font-family: "Roboto Mono", monospace;
    width: 80px;
}

.transfer-send-btn {
    background: none;
    border: none;
    padding: 5px 10px;
    background-color: aqua;
    font-family: "Roboto Mono", monospace;
    cursor: pointer;
}

.transfer-send-btn:hover {
    background: none;
    padding: 4px 9px;
    border: 1px solid black;
}
</style>