<template>
    <HeaderComponent />
    <LoadingCircle v-if="!organization" />
    <div class="organization-page" v-else>
        <div class="greetings">
            <span>{{ organization.title }}</span>
        </div>
        <div class="sections">
            <MembersSection :organizationId="organization.id" />
            <TelegramSection :organization="organization" @refresh="fetchOrganization"/>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "../Header.vue";
import MembersSection from "./MembersSection/MembersSection.vue"
import LoadingCircle from "../common/Loader.vue"
import TelegramSection from "./TelegramSection.vue"

export default {
    name: 'OrganizationPage',
    components: { HeaderComponent, MembersSection, LoadingCircle, TelegramSection },
    data() {
        return {
            organization: null,
        };
    },
    async created() {
        await this.fetchOrganization()
    },
    methods: {
        async fetchOrganization() {
            try {
                const { data } = await axios.get('/organization');
                this.organization = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
    },
};
</script>
<style>
.organization-page {
    margin: 0 5%;
}

.greetings {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 30px;
}

.sections {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.sections > div {
    border: 1px solid #bebebe;
    display: inline-block;
    padding: 20px;
    border-radius: 20px
}
.section-title{
    font-weight: 700;
    margin-bottom: 10px;
}
h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    margin-bottom: 20px;
}
</style>