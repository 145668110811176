<template>
    <div class="left-content">
        <div class="status">
            <div class="status-buttons">
                <button class="working" :class="{ 'active': strategy.status === 'Working' }"
                    v-if="strategy.status !== 'Finished'" @mouseover="selectedStatusBtn = 'working'"
                    @mouseleave="selectedStatusBtn = null" @click="toggleStrategy('start')">
                    {{ getButtonLabel('start') }}
                </button>
                <button class="interrupted" :class="{ 'active': strategy.status === 'Interrupted' }"
                    v-if="!['Finished', 'Created'].includes(strategy.status)" @mouseover="selectedStatusBtn = 'interrupted'"
                    @mouseleave="selectedStatusBtn = null" @click="toggleStrategy('interrupt')">
                    {{ getButtonLabel('interrupt') }}
                </button>
                <button class="paused" :class="{ 'active': strategy.status === 'Paused' }"
                    v-if="!['Finished', 'Created'].includes(strategy.status)" @mouseover="selectedStatusBtn = 'paused'"
                    @mouseleave="selectedStatusBtn = null" @click="toggleStrategy('pause')">
                    {{ getButtonLabel('pause') }}
                </button>
                <button class="finished" :class="{ 'active': strategy.status === 'Finished' }"
                    v-if="strategy.status !== 'Created'" @mouseover="selectedStatusBtn = 'finished'"
                    @mouseleave="selectedStatusBtn = null" @click="toggleStrategy('finish')">
                    {{ getButtonLabel('finish') }}
                </button>
            </div>
            <div v-if="selectedStatusBtn" class="status-disclaimer">
                <p v-if="selectedStatusBtn === 'finished'">
                    Once a strategy has finished, it cannot be restarted or continued. You will only be able to view its results. 
                    The Finish button implies a soft stop, i.e. after pressing it, the current candle will continue working,
                    but the strategy will not start a new one after it has finished. All remaining candlesticks go to the Ignored status. 
                    The strategy itself will also switch to the Finished status if the last candle has been worked out.
                </p>
                <p v-else-if="selectedStatusBtn === 'working'">
                    This action will launch or continue the strategy.
                    In case of the first launch, the opening time of all candles will be automatically set starting from the nearest timeframe,
                    i.e. if the timeframe is 15 minutes and the current time is XX:01, the strategy will start working at XX:15. In case of a restart,
                    the candlestick times will not be changed and the strategy will start working from the closest candlestick, not the one that was interrupted.
                </p>
                <p v-else-if="selectedStatusBtn === 'interrupted'">
                    Forcing the strategy to stop.
                    The current candle will stop immediately, but the tasks in the queue (if any) will continue to run.
                </p>
                <p v-else-if="selectedStatusBtn === 'paused'">
                    Gentle suspension of strategy. The current candle will finish its work, but the strategy will not proceed to a new one.
                </p>
            </div>
        </div>
        <div class="stats-list" v-if="strategyInfo">
            <div class="cell" v-if="strategyInfo.queued_amount !== null">
                <div class="cell-header">Progress</div>
                <div class="cell-content">
                    <span>
                        {{ ((strategy.frames_amount - strategyInfo.queued_amount) / strategy.frames_amount *
                        100).toFixed(1)
                        }}%
                    </span>
                    <span>
                        {{ strategy.frames_amount - strategyInfo.queued_amount }}/{{ strategy.frames_amount }}
                    </span>
                </div>
            </div>
            <div class="cell" v-if="strategyInfo.trades">
                <div class="cell-header">Gas fees</div>
                <div class="cell-content">
                    <span>{{ (strategyInfo.trades.gas_fees / (10 ** 18)).toFixed(3) }} {{
                        pair.swapper.network.native_symbol
                    }}</span>
                </div>
            </div>
            <div class="cell" v-if="strategyInfo.trades">
                <div class="cell-header">Trades</div>
                <div class="cell-content">
                    <span>{{ strategyInfo.trades.amount }}</span>
                    <span>{{ strategyInfo.trades.wallet_amount }} wallets</span>
                </div>
            </div>
            <div class="cell" v-if="strategyInfo.border_prices">
                <div class="cell-header">Price change</div>
                <div class="cell-content">
                    <span :class="{
                        'red-price': strategyInfo.border_prices.latest < strategyInfo.border_prices.start,
                        'green-price': strategyInfo.border_prices.latest >= strategyInfo.border_prices.start
                    }">
                        {{ parseInt((strategyInfo.border_prices.latest / strategyInfo.border_prices.start - 1) * 10000)
                        / 100 }}%
                    </span>
                </div>
            </div>
            <div class="cell" v-if="strategyInfo.usd_volume">
                <div class="cell-header">Volume generated, $</div>
                <div class="cell-content">
                    <span>{{ strategyInfo.usd_volume.toFixed(2) }}</span>
                </div>
            </div>
            <div class="cell" v-if="strategyInfo.current_candle">
                <div class="cell-header">Current candle</div>
                <div v-if="strategyInfo.current_candle.ohlc" class="cell-content current-candle">
                    <div class="candle-values">
                        <div>
                            <div class="line"></div>
                            <span>{{ strategyInfo.current_candle.ohlc.high.toFixed(4) }}</span>
                        </div>
                        <div>
                            <div class="line"></div>
                            <span v-if="candleColor === 'green'">
                                {{ strategyInfo.current_candle.ohlc.close.toFixed(4) }}
                            </span>
                            <span v-else>{{ strategyInfo.current_candle.ohlc.open.toFixed(4) }}</span>
                        </div>
                        <div>
                            <span v-if="candleColor === 'green'">
                                {{ strategyInfo.current_candle.ohlc.open.toFixed(4) }}
                            </span>
                            <span v-else>{{ strategyInfo.current_candle.ohlc.close.toFixed(4) }}</span>
                            <div class="line"></div>
                        </div>
                        <div>
                            <span>{{ strategyInfo.current_candle.ohlc.low.toFixed(4) }}</span>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="candle-wrapper"
                        :class="{ 'green-candle': candleColor === 'green', 'red-candle': candleColor === 'red' }">
                    </div>
                </div>
                <div v-else>NaN</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "LeftComponent",
    emits: ['reload'],
    props: {
        strategy: {
            type: Object,
            required: true,
        },
        strategyInfo: {
            type: Object,
            required: true,
        },
        pair: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            selectedStatusBtn: null,
        }
    },
    computed: {
        candleColor() {
            if (!this.strategyInfo) return null;
            if (!this.strategyInfo.current_candle) return null;
            if (this.strategyInfo.current_candle.ohlc.open < this.strategyInfo.current_candle.ohlc.close) {
                return 'green'
            } else {
                return 'red'
            }
        },
    },
    methods: {
        async toggleStrategy(status) {
            try {
                const url = `pair/${this.pair.id}/strategies/${this.strategy.id}/${status}`
                await axios.post(url)
                this.$root.displaySuccess('Success')
                this.$emit('reload')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
        },
        getButtonLabel(buttonType) {
            switch (buttonType) {
                case 'start':
                    return { "Working": "Working", "Created": "Launch", "Paused": "Continue", "Interrupted": "Continue" }[this.strategy.status]
                case 'interrupt':
                    return this.strategy.status === 'Interrupted' ? "Interrupted" : "Interrupt"
                case 'pause':
                    return this.strategy.status === 'Paused' ? "Paused" : "Pause"
                case 'finish':
                    return this.strategy.status === 'Finished' ? "Finished" : "Finish"
            }
        },
    },
}
</script>
<style scoped>
.left-content {
    width: 200px;
    display: flex;
    flex-direction: column;
}
.status {
    position: relative;
    margin-bottom: 10px;
}

.status>div {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #CECECE;
}

.status-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.status-buttons>button {
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 10px;
    opacity: 0.3;
}

.status-buttons>button.active {
    opacity: 1;
    cursor: default;
}

.status-buttons>button:hover {
    opacity: 1;
}

.status-buttons>.working {
    font-size: 16px;
    background: linear-gradient(90deg, #BFFFBA 0%, #74F7D7 100%);
}

.status-buttons>.interrupted {
    font-size: 16px;
    background: linear-gradient(90deg, #F1AFED 0%, #C77AE3 100%);
}

.status-buttons>.paused {
    font-size: 16px;
    background-color: #DADADA;
}

.status-buttons>.finished {
    font-size: 16px;
    background-color: #BBF6FE;
}

.status-disclaimer {
    position: absolute;
    top: 0;
    left: 210px;
    z-index: 2;
    width: 500px;
    background-color: #feff9c;
    display: inline-block;
    word-break: break-word;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}
.stats-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.cell {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #CECECE;
}

.cell-header {
    font-size: 14px;
    margin-bottom: 2px;
}

.cell>.cell-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.cell>.cell-content> :first-child {
    font-size: 24px;
}

.cell>.cell-content> :nth-child(2) {
    font-size: 14px;
}

.red-price {
    color: rgb(200, 28, 28);
}

.green-price {
    color: green;
}

.current-candle {
    margin-top: 10px;
    display: flex;
    justify-content: center !important;
    align-items: stretch !important;
    height: 100px;
    gap: 75px;
}

.candle-values {
    position: relative;
}

.candle-values>div {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.candle-values>div>.line {
    width: 101px;
    border-bottom: 1px solid #bebebe
}

.candle-values>div {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.candle-values> :nth-child(1) {
    top: 1px;
}

.candle-values> :nth-child(2) {
    top: 23px;
}

.candle-values> :nth-child(3) {
    top: 60px;
}

.candle-values> :nth-child(4) {
    top: 83px;
}

.candle-wrapper {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 50px;
    z-index: 1;
}

.candle-wrapper.green-candle {
    background-image: url('/public/icons/current_candle_green.svg');
}

.candle-wrapper.red-candle {
    background-image: url('/public/icons/current_candle_green.svg');
}
</style>