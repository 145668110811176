<template>
    <HeaderComponent />
    <div v-if="isLoaded" class="content">
        <div class="segments-holder">
            <div class="pairs segment">
                <div class="segment-header">
                    <span>My Pairs</span>
                    <button @click="isAddingNewPair = true"></button>
                </div>
                <div class="pairs-list">
                    <div v-for="pair in userPairs" :key="pair.id" class="pair-row" @click="navigateToPair(pair.id)">
                        <span class="pair-symbols">
                            {{ pair.base_token.symbol }}/{{ pair.quote_token.symbol }}
                        </span>
                        <div class="pair-right-part">
                            <span class="pair-contract">
                                {{ contractShortener(pair.contract) }}
                            </span>
                            <div class="pair-logos">
                                <img :src="require(`/public/img/swappers_logo/${pair.swapper.config.alias.front}.png`)"
                                    alt="Swapper logo" />
                                <img :src="require(`/public/img/networks_logo/${pair.swapper.network.config.alias.front}.png`)"
                                    alt="Network logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalAddPair v-if="isAddingNewPair" @close="isAddingNewPair = false" @pair-added="pairAdded" />
</template>
<script>
import HeaderComponent from "../Header.vue";
import ModalAddPair from "./ModalAddPair.vue";
import axios from "axios";

export default {
    name: "HomePage",
    data() {
        return {
            isLoaded: false,
            userData: null,
            userPairs: [],
            isAddingNewPair: false,
            isConnectingTelegram: false,
            tgActivateCodeSend: false,
            tgID: null,
            tgCode: null
        }
    },
    components: {
        HeaderComponent,
        ModalAddPair
    },
    async created() {
        await this.getUser();
        await this.getUserPairs();
        this.isLoaded = true;
    },
    methods: {
        async getUser() {
            try {
                const { data } = await axios.get('/user');
                this.userData = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        async getUserPairs() {
            try {
                const { data } = await axios.get('/pair');
                this.userPairs = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        navigateToPair(pairId) {
            this.$router.push(`/pair/${pairId}`);
        },
        contractShortener(value) {
            return value.substr(0, 4) + ".." + value.substr(-3);
        },
        async pairAdded() {
            this.isAddingNewPair = false;
            await this.getUserPairs();
        }
    },
}
</script>
<style scoped>
.content {
    margin: 0 5%;
}

.segments-holder {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}

.segment {
    padding: 15px;
    border: 1px solid black;
    width: 400px;
}

.pairs-list {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.pair-row {
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
    cursor: pointer;
}

.pair-row:hover {
    background-color: aliceblue;
}

.pair-symbols {
    font-size: 18px;
}

.pair-right-part {
    display: flex;
    align-items: center;
    gap: 15px;
}

.pair-logos {
    display: flex;
    align-items: center;
    gap: 5px;
}

.pair-logos>img {
    width: auto;
    height: 25px;
}

.segment-header {
    font-size: 24px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.segment-header>button {
    cursor: pointer;
    width: 17px;
    height: 17px;
    background-size: cover;
    background-image: url('/public/icons/add.svg');
}

.segment-header>button:hover {
    background-color: transparent;
}
</style>