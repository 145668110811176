<template>
    <div class="chart-generator-content">
        <div class="mode-switcher">
            <label>
                <input type="radio" v-model="mode" value="auto" />
                Autogenerate
            </label>
            <label>
                <input type="radio" v-model="mode" value="handdrawn" />
                HandDrawn
            </label>
        </div>
        <div class='chart-generator'>
            <AutoGenerator v-if="mode === 'auto'" :settings="settings" :initPrice="initPrice" @generated="generated" />
            <HanddrawnGenerator v-if="mode === 'handdrawn'" :settings="settings" :initPrice="initPrice" @generated="generated"/>
        </div>
    </div>
</template>
<script>
import AutoGenerator from "./Auto.vue"
import HanddrawnGenerator from "./Handdrawn.vue"

export default {
    name: "ChartGenerator",
    components: { AutoGenerator, HanddrawnGenerator },
    emits: ['generated'],
    props: {
        settings: {
            type: Object,
            required: true
        },
        initPrice: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            mode: "auto",
        }
    },
    methods: {
        generated(values) {
            const ohlc = this.generateCandles(values)
            this.$emit('generated', ohlc)
        },
        generateCandles(opens) {
            const ohlcData = opens.map((price, index, arr) => {
                if (index === arr.length - 1) return null;
                const open = price;
                const close = arr[index + 1];
                const high = Math.max(open, close) * (1 + this.settings.shadows * Math.random());
                const low = Math.min(open, close) * (1 - this.settings.shadows * Math.random());
                return {
                    open,
                    high: Math.max(high, open, close),
                    low: Math.min(low, open, close),
                    close
                };
            }).filter(val => val !== null);
            return ohlcData;
        },
    }
}
</script>
<style scoped>
.chart-generator-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.mode-switcher {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.mode-switcher>label {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #bebebe;
    cursor: pointer;
}
</style>