<template>
    <ModalWindow :title="`Strategy ${strategyTitle}`" :isLoaded="isLoaded" :width="'auto'" @close="$emit('close')">
        <div class="content">
            <LeftComponent :pair="pair" :strategy="strategy" :strategyInfo="strategyInfo" @reload="reload"/>
            <div class="right-content">
                <div class="strategy-visual-container">
                    <ChartComponent v-if="rightWindowMode === 'chart'" :strategyCandles="candles"
                        :startPrice="chartStartPrice" :timeframe="strategy.timeframe"
                        :currentCandleInfo="currentCandleInfo" />
                    <LogsComponent v-if="rightWindowMode === 'logs'" :logs="strategyLogs" @loadLogs="fetchLogs"/>
                    <div class="chart-logs-switch">
                        <span>Chart</span>
                        <ToggleSwitch :onNewVal="toggleRightWindow" />
                        <span>Logs</span>
                    </div>
                </div>
                <SettingsComponent :strategy="strategy" @reload="reload"/>
            </div>
        </div>
    </ModalWindow>
</template>
<script>
import ModalWindow from "@/components/common/Modal.vue"
import LeftComponent from "./LeftComponent.vue"
import LogsComponent from "./LogsComponent.vue"
import ToggleSwitch from "@/components/common/ToggleSwitch.vue"
import SettingsComponent from "./SettingsComponent.vue"
import axios from "axios";
import ChartComponent from "./ChartComponent.vue"
import { findNextCandleOpenTime, timeframeToSeconds } from '@/utils/funcs'

export default {
    name: "StrategyModal",
    emits: ['close'],
    components: { ModalWindow, ToggleSwitch, LeftComponent, LogsComponent, ChartComponent, SettingsComponent },
    props: {
        pair: {
            type: Object,
            required: true
        },
        strategyTitle: {
            type: String,
            required: true,
        },
        strategyId: {
            type: String,
            required: true,
        },
        reserves: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isLoaded: false,
            rightWindowMode: null,

            strategy: null,
            strategyInfo: null,
            strategyLogs: null,
            candles: null,
            currentCandleInfo: null,
        }
    },
    async mounted() {
        await this.load();
    },
    computed: {
        currentPrice() {
            return this.reserves[1] / this.reserves[0]
        },
        chartStartPrice() {
            if (this.strategy.status === 'Created') return this.currentPrice;
            return this.strategy.start_price;
        }
    },
    methods: {
        async reload() {
            this.isLoaded = false;
            await this.load();
        },  
        async load() {
            await this.fetchStrategy();
            await this.fetchCandles();
            if (this.strategy.status === 'Created') {
                const nextOpen = findNextCandleOpenTime(this.strategy.timeframe)
                const secondsGap = timeframeToSeconds(this.strategy.timeframe);

                this.candles.forEach((candle, index) => {
                    const newTime = new Date(nextOpen.getTime() + index * secondsGap * 1000);
                    candle.open_time = newTime.toISOString().slice(0, -1);
                });
            }
            if (this.strategy.status !== 'Created') {
                await this.fetchStrategyInfo();
                if (this.strategyInfo.border_prices.start === null) {
                    this.$root.displaySuccess('Strategy has not picked first candle yet')
                    this.strategyInfo.isPending = true;
                }
                if (this.strategyInfo.current_candle) {
                    await this.fetchCurrentCandleInfo();
                }
            }
            this.isLoaded = true;
            this.rightWindowMode = 'chart';
        },
        toggleRightWindow(newVal) {
            this.rightWindowMode = { false: 'chart', true: 'logs' }[newVal]
        },
        async fetchStrategy() {
            try {
                const url = `pair/${this.pair.id}/strategies/${this.strategyId}`
                const { data } = await axios.get(url)
                this.strategy = data;
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
        },
        async fetchStrategyInfo() {
            try {
                const url = `pair/${this.pair.id}/strategies/${this.strategyId}/info`
                const { data } = await axios.get(url)
                this.strategyInfo = data;
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
        },
        async fetchCandles() {
            try {
                const url = `pair/${this.pair.id}/strategies/${this.strategyId}/candles`
                const { data } = await axios.get(url)
                this.candles = data;
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
        },
        async fetchCurrentCandleInfo() {
            try {
                const url = `pair/${this.pair.id}/strategies/${this.strategyId}/candles/${this.strategyInfo.current_candle.id}/info`
                const { data } = await axios.get(url)
                this.currentCandleInfo = data;
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
        },
        async fetchLogs(params) {
            console.log(params)
            try {
                const url = `pair/${this.pair.id}/strategies/${this.strategyId}/logs`
                const { data } = await axios.get(url, { params: {
                    offset: params.offset,
                    limit: params.limit
                }})
                if (params.more) {
                    this.strategyLogs.push(...data)
                } else {
                    this.strategyLogs = data;
                }
                this.$root.displaySuccess('Logs loaded')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
        },
    }
}
</script>
<style scoped>
.content {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.strategy-visual-container {
    position: relative;
    width: 1300px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #CECECE;
    height: 600px;
    margin-bottom: 10px;
}

.chart-logs-switch {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 20px;
    opacity: 0.3;
    z-index: 4;
    gap: 5px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #CECECE;
}

.chart-logs-switch:hover {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    opacity: 1;
}

.chart-logs-switch>span {
    width: 60px;
    text-align: center;
    padding-bottom: 5px;
}
</style>