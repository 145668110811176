<template>
    <div class="handrawn-chart-content">
        <div class="handrawn-chart-container">
            <div class="handrawn-chart-header">
                <div class="scale-settings">
                    <input
                    type="range"
                    v-model="scalingFactor"
                    min="5" max="100"
                    @input="onScalingChange"
                    />
                </div>
                <span class="handrawn-dots-counter">Dots: {{ dotsAmount }}</span>
                <div>
                    <button @click="reset">Clear</button>
                </div>
            </div>
            <canvas id="basic-handdrawn-canvas"
            @mousedown="startDrawing"
            @mousemove="onDrawing"
            @mouseup="finishDrawing">
            </canvas>
            <div class="handdrawn-footer">
                <div class="pertrubation-container">
                    <input v-model="pertrubation" type="number">
                    <p>% pertrubation</p>
                </div>
                <button @click="generateOpens">Generate</button>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "HanddrawnGenerator",

    props: {
        settings: {
            type: Object,
            required: true,
        },
        initPrice: {
            type: Number,
            required: true,
        },
    },
    emits: ['generated'],
    data() {
        return {
            chartLocation: null,

            isDrawing: false,
            isChartDone: false,
            
            scalingFactor: 100,
            pertrubation: 0.05,

            canvasData: [],
            lastPoint: null,
        };
    },
    mounted() {
        this.chartLocation = document.getElementById('basic-handdrawn-canvas');
        const chartArea = this.chartLocation.getBoundingClientRect();
        this.chartLocation.width = chartArea.width;
        this.chartLocation.height = chartArea.height;
        this.initializeCanvas();
    },
    computed: {
        dotsAmount() {
            return this.canvasData.length;
        }
    },
    methods: {
        initializeCanvas() {
            this.drawCanvasLayout();
            this.drawScalingFactor();
            this.drawScalingFactor();
        },
        clearCanvas() {
            const ctx = this.chartLocation.getContext('2d');
            ctx.clearRect(0, 0, this.chartLocation.width, this.chartLocation.height);
        },
        drawCanvasLayout() { 
            const ctx = this.chartLocation.getContext('2d');
            const { width, height } = this.chartLocation;
            ctx.beginPath();
            ctx.strokeStyle = "#bebebe";
            ctx.moveTo(0, height / 2);
            ctx.lineTo(width, height / 2);
            ctx.stroke();
        },
        drawScalingFactor() {
            const ctx = this.chartLocation.getContext('2d');
            const { height } = this.chartLocation;
            ctx.clearRect(0, 0, 40, 30);
            ctx.clearRect(0, height - 30, 40, 30);
            ctx.fillText(`${this.scalingFactor}%`, 10, 20);
            ctx.fillText(`-${this.scalingFactor}%`, 10, height - 10);
        },
        reset() {
            this.clearCanvas()
            this.initializeCanvas()
            this.canvasData = [];
            this.lastPoint = null;
        },
        startDrawing(e) {
            this.isDrawing = true;
            this.onDrawing(e);
        },
        onDrawing(e) {
            if (!this.isDrawing) return;

            const ctx = this.chartLocation.getContext('2d');

            ctx.fillRect(e.offsetX, e.offsetY, 2, 2);

            const newPoint = { x: e.offsetX, y: e.offsetY };

            if (this.lastPoint) {
                const dist = Math.sqrt(Math.pow(newPoint.x - this.lastPoint.x, 2) + Math.pow(newPoint.y - this.lastPoint.y, 2));
                const angle = Math.atan2(newPoint.y - this.lastPoint.y, newPoint.x - this.lastPoint.x);

                for (let i = 0; i < dist; i++) {
                    let x = this.lastPoint.x + (Math.cos(angle) * i);
                    let y = this.lastPoint.y + (Math.sin(angle) * i);
                    this.canvasData.push(y);
                    ctx.fillRect(x, y, 2, 2);
                }
            }
            this.lastPoint = newPoint;
        },
        finishDrawing() {
            this.isDrawing = false;
        },
        onScalingChange() {
            this.drawScalingFactor(this.chartLocation)
        },
        generateOpens() {
            if (this.canvasData.length < this.settings.framesAmount) {
                this.$root.displayError('You do not have enough dots');
                return;
            }
            const totalPoints = this.canvasData.length;
            const framesAmount = this.settings.framesAmount;
            const chunkSize = Math.floor(totalPoints / framesAmount);
            let opens = [this.initPrice];

            for (let i = 1; i < framesAmount + 1; i++) {
                const chunkStart = i * chunkSize;
                const chunkEnd = chunkStart + chunkSize;
                const chunk = this.canvasData.slice(chunkStart, chunkEnd);
                const averageY = chunk.reduce((acc, curr) => acc + curr, 0) / chunk.length;

                const deltaY = this.canvasData[0] - averageY;
                const priceChange = deltaY / this.chartLocation.height * this.scalingFactor * 2;
                const scaledPrice = this.initPrice * (1 + priceChange / 100);
                const pertrubated = scaledPrice * (1 + (Math.random() - 0.5) * this.pertrubation)
                opens.push(pertrubated);
            }
            this.$emit('generated', opens)
        },
    },
};
</script>

<style scoped>
.handrawn-dots-counter {
    user-select: none;
    font-size: 14px;
}

canvas {
    border: 1px solid #717171;
    border-radius: 5px;
    width: 100%;
}

.handrawn-chart-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type="range"] {
    height: 5px;
    width: 100px;
}

.handrawn-chart-container {
    width: 80%;
    border: 1px solid #bebebe;
    padding: 15px 20px 20px 20px;
    border-radius: 10px;
}

.handrawn-chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.handrawn-chart-header>* {
    text-align: center;
    width: 200px;
}

.handrawn-chart-header> :first-child {
    display: flex;
    align-items: center;
    gap: 5px;
}

.handrawn-chart-header> :last-child {
    text-align: right;
}

.handrawn-chart-header> :last-child > button {
    background-color: #eee;
    padding: 6px 12px;
    border-radius: 5px;
}
.handrawn-chart-header> :last-child > button:hover{
    background-color: #bebebe;
}
.handdrawn-footer{
    margin-top: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
}
.pertrubation-container{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    text-wrap: nowrap;
}
.pertrubation-container > input{
    width: 50px;
    text-align: right;
    padding: 5px;
    border-radius: 5px;
}
.handdrawn-footer > button {
    width: 100%;
    padding: 7px 0;
    background-color: #eee;
    border-radius: 5px;
}
.handdrawn-footer > button:hover{
    background-color: aqua;
}
</style>
