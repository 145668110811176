<template>
    <ModalWindow :title="'Verification'" @close="$emit('close')">
        <LoadingCircle v-if="isVerified === null" />
        <div v-else class="content">
            <div class="send-code">
                <input class="cs-input small" v-model="code">
                <div class="resend-container">
                    <span v-if="resendWait > 0">{{ resendWait }}</span>
                    <button class="cs-button" :disabled="resendWait > 0" @click="resend">Resend</button>
                </div>
            </div>
            <div class="keep-verified">
                <span>Keep me verified for </span>
                <select class="cs-select small non-empty" v-model="ignoreMinutes">
                    <option value="1">1 minute</option>
                    <option value="5">5 minutes</option>
                    <option value="10">10 minutes</option>
                    <option value="30">30 minutes</option>
                    <option value="60">60 minutes</option>
                </select>
            </div>
            <button @click="verify" :disabled="code.length === 0" class="cs-button next">Verify</button>
        </div>
    </ModalWindow>
</template>
<script>
import axios from "axios";
import ModalWindow from "./common/Modal.vue"
import LoadingCircle from "./common/Loader.vue"

export default {
    name: "VerificationModal",
    components: { ModalWindow, LoadingCircle },
    emits: ["success", "close"],
    data() {
        return {
            isVerified: null,

            code: "",
            ignoreMinutes: '5',

            verificationId: null,
            timer: null, 
            resendWait: null,
        }
    },
    watch: {
        isVerified(newVal) {
            if (newVal === true) {
                this.$emit("success");
                this.$emit("close");
            }
        }
    },
    async created() {
        await this.fetchIsVerified();
        if (!this.isVerified) {
            await this.start()
        }
    },
    beforeUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        startTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.resendWait > 0) {
                    this.resendWait--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        },
        async fetchIsVerified() {
            try {
                const url = '/user-session/verification'
                const { data } = await axios.get(url)
                if (data.verified) {
                    this.$root.displaySuccess('You are already verified')
                } else {
                    this.$root.displayError('You are not verified')
                }
                this.isVerified = data.verified;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)   
            }
        },
        async start() {
            try {
                const url = '/user-session/verification/start'
                const {data} = await axios.post(url)
                this.verificationId = data.verification_id;
                this.resendWait = data.resend_wait;
                this.$root.displaySuccess('Code was sent')
                this.startTimer();
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }   
        },
        async resend() {
            try {
                const url = '/user-session/verification/resend'
                const {data} = await axios.post(url, {
                    old_verification_id: this.verificationId,
                })
                this.verificationId = data.verification_id;
                this.resendWait = data.resend_wait;
                this.$root.displaySuccess('Code was sent')
                this.startTimer();
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }   
        },
        async verify() {
            try {
                const url = '/user-session/verification/verify';
                await axios.post(url, { code: this.code, ignore_minutes: this.ignoreMinutes, verification_id: this.verificationId })
                this.isVerified = true;
                this.$root.displaySuccess('Verified successfully')
            } catch (error) {
                if (error.response.status === 403) {
                    this.$root.displayError("Amount of failed attempts has been exceeded");    
                    return;
                }
                this.$root.displayError(error.response.data.detail);
            }
        },
    }
}
</script>
<style scoped>
.content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 300px;
}
.cs-button.next{
    margin-top: 20px;
}
.keep-verified {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 12px;
}

.send-code {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.verify-btn{
    padding: 6px;
    border: 1px solid aqua;
}
.resend-container{
    display: flex;
    gap: 20px;
    align-items: center;
}
</style>