<template>
    <div class="autogen-content">
        <div class="autogen-cell-row">
            <div class="autogen-cell">
                <input
                type="number"
                v-model="deltaPrice.value"
                @input="validateDeltaPrice"
                :class="{invalid: !deltaPrice.isValid}"
                />
                <p>% price change </p>
            </div>
            <div class="autogen-cell">
                <input
                type="number"
                v-model="volatility.value"
                @input="validateVolatility"
                :class="{invalid: !volatility.isValid}"
                />
                <p>% volatility </p>
            </div>
            <div class="tunnel-container">
                <div class="tunnel-params" :class="{ disabled: !isTunnel }">
                    <div class="autogen-cell">
                        <input
                        :disabled="!isTunnel"
                        type="number"
                        v-model="lowerBorder.value"
                        @input="validateLowerBorder"
                        :class="{invalid: !lowerBorder.isValid}"
                        />
                        <span>% lower border</span>
                    </div>
                    <div class="autogen-cell">
                        <input
                        :disabled="!isTunnel"
                        type="number"
                        v-model="upperBorder.value"
                        @input="validateUpperBorder"
                        :class="{invalid: !upperBorder.isValid}"
                        />
                        <span>% upper border</span>
                    </div>
                </div>
                <div>
                    <p @click="isTunnel = !isTunnel" :class="{ triggered: isTunnel }" class="tunnel-trigger">
                        Strict tunnel
                    </p>
                </div>
            </div>
            <p class="randomize-btn" @click="randomizeParams">
                Randomize
            </p>
            <div class="autogen-footer">
                <button @click="generate" class="generate-btn">Generate</button>
            </div>
            
        </div>
    </div>
</template>

<script>

export default {
    name: "AutoGenerator",
    emits: ['generated'],
    data() {
        return {
            isTunnel: false,
            deltaPrice: {
                value: "10",
                isValid: true,
            },
            volatility: {
                value: "2",
                isValid: true,
            },
            lowerBorder: {
                value: "10",
                isValid: true,
            },
            upperBorder: {
                value: "10",
                isValid: true,
            },
        }
    },
    props: {
        settings: {
            type: Object,
            required: true,
        },
        initPrice: {
            type: Number,
            required: true,
        },
    },
    methods: {
        validateDeltaPrice(event) {
            const value = parseFloat(event.target.value);
            if (value < 500 && value > -90) {
                this.deltaPrice.value = value;
                this.deltaPrice.isValid = true;
                return;
            }
            this.deltaPrice.isValid = false;
        },
        validateVolatility(event) {
            const value = parseFloat(event.target.value);
            if (value < 500 && value > 0) {
                this.volatility.value = value;
                this.volatility.isValid = true;
                return;
            }
            this.volatility.isValid = false;
        },
        validateLowerBorder(event) {
            const value = parseFloat(event.target.value);
            if (value < 90 && value > 0) {
                this.lowerBorder.value = value;
                this.lowerBorder.isValid = true;
                return;
            }
            this.lowerBorder.isValid = false;
        },
        validateUpperBorder(event) {
            const value = parseFloat(event.target.value);
            if (value < 500 && value > 0) {
                this.upperBorder.value = value;
                this.upperBorder.isValid = true;
                return;
            }
            this.upperBorder.isValid = false;
        },
        randomFloat(min, max, decimals = 3) {
            const range = max - min;
            const random = Math.random() * range + min;
            return parseFloat(random.toFixed(decimals));
        },
        randomizeParams() {
            this.deltaPrice.value = this.randomFloat(-30, 30, 2)
            this.volatility.value = this.randomFloat(1, 5, 2)
            this.lowerBorder.value = this.randomFloat(0, 50, 2)
            this.upperBorder.value = this.randomFloat(0, 50, 2)
        },
        generate() {
            let opens = this.generateOpens()
            if (this.isTunnel) {
                opens = this.generateOpensWithBorder()
            }
            this.$emit("generated", opens);
        },
        generateOpens() {
            const framesAmount = this.settings.framesAmount;
            const deltaPrice = this.deltaPrice.value / 100;
            const initialPrice = this.initPrice;
            const averageStepChange = deltaPrice / framesAmount;
            let opens = [initialPrice];

            for (let i = 1; i < framesAmount + 1; i++) {
                const stepChange = averageStepChange + (Math.random() - 0.5) * this.volatility.value / 100;
                const newPrice = opens[i - 1] * (1 + stepChange);
                opens.push(newPrice);
            }
            return opens;
        },
        generateOpensWithBorder(bounceEffect = 0.3) {
            let prices = [this.initPrice];

            function normalRandom(scale = 1, offset = 0) {
                let u = 0, v = 0;
                while (u === 0) u = Math.random();
                while (v === 0) v = Math.random();
                return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v) * scale + offset;
            }

            const finalPrice = this.initPrice * (1 + this.deltaPrice.value / 100);
            const upperBorder = this.initPrice * (1 + this.upperBorder.value / 100);
            const lowerBorder = this.initPrice * (1 - this.lowerBorder.value / 100);

            for (let t = 1; t <= this.settings.framesAmount; t++) {
                const fraction = t / this.settings.framesAmount;
                const currentLowerBound = lowerBorder + (finalPrice - this.initPrice) * fraction;
                const currentUpperBound = upperBorder + (finalPrice - this.initPrice) * fraction;

                let stepChange = normalRandom(this.volatility.value / 100);
                let potentialNewPrice = prices[prices.length - 1] * (1 + stepChange);

                if (potentialNewPrice >= currentUpperBound) {
                    stepChange = -Math.abs(normalRandom(this.volatility.value / 100) * bounceEffect);
                } else if (potentialNewPrice <= currentLowerBound) {
                    stepChange = Math.abs(normalRandom(this.volatility.value / 100) * bounceEffect);
                }

                let newPrice = prices[prices.length - 1] * (1 + stepChange);
                newPrice = Math.max(Math.min(newPrice, currentUpperBound), currentLowerBound);
                prices.push(newPrice);
            }
            return prices;
        },
    }
}
</script>
<style scoped>
input:disabled {
    background-color: #eee;
    border-color: #eee;
}

input {
    text-align: right;
}

.autogen-cell-row {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: flex-start;
}

.autogen-cell {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #bebebe;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
}

.autogen-cell>p {
    margin: 0;
    display: inline
}

.autogen-cell>input[type="number"] {
    width: 60px;
    padding: 5px;
    border-radius: 5px;
}

.tunnel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.tunnel-params {
    display: flex;
    gap: 10px;
}

.tunnel-params.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.tunnel-trigger {
    color: #bebebe;
    cursor: pointer;
    margin: 0;
    font-size: 14px;
}

.tunnel-trigger.triggered {
    color: black;
}

.tunnel-trigger:hover {
    text-decoration: underline;
}

.randomize-btn {
    padding: 0 10px;
    margin: 15px 0;
    color: rgb(99, 99, 99);
    cursor: pointer;
    user-select: none;
}

.randomize-btn:hover {
    text-decoration: underline;
}

.generate-btn {
    padding: 14px;
    font-size: 16px;
    background-color: rgb(255, 161, 228);
    border-radius: 5px;
}

.generate-btn:hover {
    background-color: rgb(225, 110, 192);
}
.invalid{
    border-color: red;
}
</style>