<template>
    <div class="modal-background" @click="close">
        <div class="modal-content" @click.stop>
            <div class="modal-header">
                <span>{{ title }}</span>
                <button class="modal-close" @click="close">&times;</button>
            </div>
            <LoadingCircle v-if="isLoaded === false"/>
            <div class="modal-body" v-else>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingCircle from "./Loader.vue"

export default {
    name: "ModalWindow",
    components: { LoadingCircle },
    props: {
        title: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: false,
            default: "500px",
        },
        isLoaded: {
            type: Boolean,
            default: null,
        }
    },
    mounted() {
        window.addEventListener("keydown", this.onEscKeyPress);
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.onEscKeyPress);
    },
    methods: {
        close() {
            this.$emit('close');
        },
        onEscKeyPress(event) {
            if (event.key === "Escape") {
                this.close();
            }
        },
    }
}
</script>

<style>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 100;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: scroll;
    max-height: 94vh;
    width: v-bind(width);
}

.modal-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header>span {
    font-family: 'Oxygen';
    font-size: 24px;
    font-weight: 700;
}

.modal-header>button:hover {
    background-color: transparent;
}

.modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-section-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
}

.modal-section {
    margin-top: 20px;
}

.open-in-explorer-btn {
    margin-top: 5px;
    display: block;
    width: auto;
    padding: 3px 6px;
    background-color: #eee;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
}

.modal-footer {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #bebebe;
}
</style>
