<template>
    <div class="chart-settings-content">
        <div class="cell">
            <p>Timeframe</p>
            <select v-model="timeframe.value">
                <option value="5m">5 minutes</option>
                <option value="10m">10 minutes</option>
                <option value="15m">15 minutes</option>
                <option value="30m">30 minutes</option>
                <option value="1h">1 hour</option>
                <option value="2h">2 hours</option>
                <option value="4h">4 hours</option>
                <option value="6h">6 hours</option>
                <option value="24h">24 hours</option>
            </select>
        </div>
        <div class="cell">
            <p>Frames</p>
            <input type="number" v-model.number="framesAmount.value" @input="validateTimeframeAmount" :class="{invalid: !framesAmount.isValid}"/>
        </div>
        <div class="cell">
            <p>Shadows, %</p>
            <input type="number" v-model.number="shadowsPercent.value" @input="validateShadows" :class="{invalid: !shadowsPercent.isValid}"/>
        </div>
        <div class="cell">
            <p>Total time</p>
            <span class="total-time"> {{ totalTime }}</span>
        </div>
    </div>
</template>
<script>

export default {
    name: "ChartSettings",
    emits: ['updateSettings'],
    data() {
        return { 
            timeframe: {
                value: "5m",
                isValid: true,
            },
            framesAmount: {
                value: "100",
                isValid: true,
            },
            shadowsPercent: {
                value: "1",
                isValid: true,
            },
        }
    },
    created() {
        this.$emit('updateSettings', this.settings)
    },
    computed: {
        totalTime() {
            if (!this.timeframe.isValid || !this.framesAmount.isValid) return;
            const total_minutes = this.timeframeInMinutes(this.timeframe.value) * this.framesAmount.value;
            const days = Math.floor(total_minutes / 1440);
            const hours = Math.floor((total_minutes % 1440) / 60);
            const remainingMinutes = total_minutes % 60;
            let duration = "";
            if (days > 0) {
                duration += `${days} day${days > 1 ? "s" : ""} `;
            }
            if (hours > 0) {
                duration += `${hours} hour${hours > 1 ? "s" : ""} `;
            }
            if (remainingMinutes > 0) {
                duration += `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
            }
            return duration.trim();
        },
        settings() {
            if (!this.timeframe.isValid || !this.framesAmount.isValid || !this.shadowsPercent.isValid) return;
            return {
                timeframe: this.timeframe.value,
                framesAmount: parseInt(this.framesAmount.value),
                shadows: this.shadowsPercent.value / 100
            }
        }
    },
    watch: {
        settings(newVal) {
            if (newVal) {
                this.$emit('updateSettings', newVal)
            }
        }
    },
    methods: {
        validateTimeframeAmount(event) {
            const value = parseInt(event.target.value)
            if (value > 0 && value <= 1000) {
                this.framesAmount = {value: value, isValid: true};
                return
            }
            this.framesAmount.isValid = false;
        },
        validateShadows(event) {
            const value = parseFloat(event.target.value)
            if (value >= 0 && value < 70) {
                this.shadowsPercent = {value: value, isValid: true};
                return
            }
            this.shadowsPercent.isValid = false;
        },
        timeframeInMinutes(value) {
            const unit = value[value.length - 1];
            const amount = parseInt(value.slice(0, -1), 10);

            switch (unit) {
                case 'm':
                    return amount;
                case 'h':
                    return amount * 60;
                default:
                    return 0;
            }
        },
    }
}
</script>
<style scoped>
.chart-settings-content{
    display: flex;
    justify-content: center;
    gap: 30px;
}
.cell{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    background-color: #eee;
    padding: 10px;
    border-radius: 10px;
}
.cell > p{
    margin: 0;
    font-size: 12px;
    color: rgb(80, 80, 80);
}
.cell > input,
.cell > select,
.total-time{
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}
.cell > input{
    width: 100px;
    text-align: right
}
.cell > span {
    width: 180px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap
}
.total-time{
    border: 1px solid black;
}
.invalid{
    border: 1px solid red;
}
</style>