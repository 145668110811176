<template>
    <div :class="{ 'quick-action-disabled': !isActive }">
        <div class='quick-action-header'>
            <span>Move price</span>
        </div>
        <div class="quick-action-params">
            <div class="quick-action-cell">
                <p>direction</p>
                <select v-model="direction" class="direction-value">
                    <option value="up">Up</option>
                    <option value="down">Down</option>
                </select>
            </div>
            <div class="quick-action-cell">
                <p>by, %</p>
                <input v-model="byPercentStr.inputValue" type='number' @input="validateByPercent"
                    :class="{ error: !byPercentStr.isValid }">
            </div>
            <div class="quick-action-cell">
                <p>% slip</p>
                <input v-model="slippageStr.inputValue" class="slippage-value" placeholder="%" @input="validateSlippage"
                    :class="{ error: !slippageStr.isValid }" type="number">
            </div>
        </div>
        <div class="estimation-container">
            <div v-if="afterMoveData" class="estimation multy">
                <div>
                    <p>new price</p>
                    <b>{{ round(afterMoveData.price, 5) }}</b>
                </div>
                <div>
                    <p v-if="afterMoveData.base > 0">spend</p>
                    <p v-else>receive</p>
                    <b>{{ round(Math.abs(afterMoveData.base), 5) }}</b>
                    <b>{{ baseToken.symbol }}</b>
                </div>
                <div>
                    <p v-if="afterMoveData.quote > 0">spend</p>
                    <p v-else>receive</p>
                    <b>{{ round(Math.abs(afterMoveData.quote), 5) }}</b>
                    <b>{{  quoteToken.symbol }}</b>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { calculatePoolChangesAmounts } from '@/utils/funcs'

export default {
    name: "MovePrice",
    emits: ["tradeReady"],
    props: {
        isActive: {
            type: Boolean,
            required: true,
            default: false,
        },
        baseToken: {
            type: Object,
            required: true
        },
        quoteToken: {
            type: Object,
            required: true
        },
        reserves: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            direction: 'up',
            byPercentStr: {
                inputValue: '0',
                isValid: false,
            },
            slippageStr: {
                inputValue: '5',
                isValid: true,
            },
        }
    },
    computed: {
        afterMoveData() {
            if (!(this.byPercentStr.isValid && this.slippageStr.isValid)) {
                return null;
            }
            const currentPrice = this.reserves[1] / this.reserves[0];
            const coeff = this.byPercentStr.inputValue / 100;
            const difference = this.direction === 'up' ? (1 + (coeff)) : (1 - (coeff))

            const newPrice = currentPrice * difference
            const [baseDelta, quoteDelta] = calculatePoolChangesAmounts(this.reserves[0], this.reserves[1], newPrice);

            const amountIn = this.direction === 'up' ? quoteDelta : baseDelta;
            const amountOutMin = this.direction === 'down' ? quoteDelta : baseDelta;

            const tradeData = {
                from: this.direction === 'up' ? 'quote' : 'base',
                to: this.direction === 'down' ? 'quote' : 'base',
                amountIn: Math.abs(amountIn),
                amountOutMin: Math.abs(amountOutMin * (1 - (this.slippageStr.inputValue / 100))),
            };
            this.$emit('tradeReady', tradeData);
            return {
                price: parseFloat(newPrice),
                base: parseFloat(baseDelta),
                quote: parseFloat(quoteDelta)
            }
        },
    },
    methods: {
        validateByPercent(event) {
            const value = parseFloat(event.target.value)
            if (isNaN(value) || value <= 0 || value > 500) {
                this.byPercentStr.isValid = false;
                return
            }
            this.byPercentStr.isValid = true;
            this.byPercentStr.inputValue = value.toString()
        },
        validateSlippage(event) {
            const value = parseFloat(event.target.value)
            if (isNaN(value) || value < 0 || value > 50) {
                this.slippageStr.isValid = false;
                return
            }
            this.slippageStr.isValid = true;
            this.slippageStr.inputValue = value.toString()
        },
        round(value, digits) {
            return parseInt(value * (10 ** digits)) / (10 ** digits)
        },
    }
}
</script>
<style scoped>
select,
input {
    padding: 3px 5px;
    border: 1px solid #000000;
    border-radius: 5px;
}

input {
    width: 70px;
    text-align: right;
}

.direction-value {
    width: 80px;
    text-align: right;
}

.slippage-value {
    width: 50px;
}

.estimation.multy {
    gap: 5px;
}
</style>