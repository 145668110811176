import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../components/Outer/Login/LoginPage.vue";
import ProfilePage from "../components/Profile/ProfilePage.vue";
import OrganizationPage from "../components/Organization/OrganizationPage.vue";
import HomePage from "../components/Home/HomePage.vue";
import PairPage from "../components/Pair/PairPage.vue";
import RegisrerPage from "../components/Outer/Register/RegisterPage.vue"

import ErrorPage503 from "@/components/errors/Page503.vue";
import ErrorPage500 from "@/components/errors/Page500.vue";

const routes = [
  { path: "/", redirect: "/home" },
  { path: "/organization", component: OrganizationPage },
  { path: "/profile", component: ProfilePage },
  { path: "/home", component: HomePage },
  { path: "/pair/:pairId", component: PairPage, props: true },

  { path: "/register", component: RegisrerPage },
  { path: "/login", component: LoginPage },

  { path: "/503", component: ErrorPage503 },
  { path: "/500", component: ErrorPage500 },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
