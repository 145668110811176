<template>
    <LoadingCircle v-if="!isLoaded" />
    <div v-else class="tool-container">
        <div class="tool-header">
            <div class="tool-header-title">Balances</div>
            <div class="tool-header-buttons">
                <button @click="modalsStatus.transfer = true" id="balances-transfer-btn"></button>
                <button @click="fetchBalances" id="balances-refresh-btn"
                    :class="{ 'refreshing-circle': isRefreshing }"></button>
            </div>
        </div>
        <div class="balances-pm-pw-container">
            <div class="balances-pm-pw">
                <div class="balances-pm-pw-header">
                    <span @click="modalsStatus.primaryWallet = true" class="balances-sub-title">
                        Primary Wallet
                    </span>
                </div>
                <div class="balances-values-holder">
                    <div class="balances-values-row">
                        <span>{{ balanceCorrector(balances.primary_wallet.native_balance, 18) }}</span>
                        <span>{{ pair.swapper.network.native_symbol }}</span>
                    </div>
                    <div class="balances-values-row">
                        <span>{{ balanceCorrector(balances.primary_wallet.base_balance, pair.base_token.decimals)
                            }}</span>
                        <span>{{ pair.base_token.symbol }}</span>
                    </div>
                    <div class="balances-values-row">
                        <span>{{ balanceCorrector(balances.primary_wallet.quote_balance, pair.quote_token.decimals)
                            }}</span>
                        <span>{{ pair.quote_token.symbol }}</span>
                    </div>
                </div>
            </div>
            <div class="balances-pm-pw">
                <div class="balances-pm-pw-header">
                    <span @click="modalsStatus.manager = true" class="balances-sub-title">
                        Manager
                    </span>
                </div>
                <div class="balances-values-holder">
                    <div class="balances-values-row">
                        <span>{{ balanceCorrector(balances.manager.native_balance, 18) }}</span>
                        <span>{{ pair.swapper.network.native_symbol }}</span>
                    </div>
                    <div class="balances-values-row">
                        <span>{{ balanceCorrector(balances.manager.base_balance, pair.base_token.decimals) }}</span>
                        <span>{{ pair.base_token.symbol }}</span>
                    </div>
                    <div class="balances-values-row">
                        <span>{{ balanceCorrector(balances.manager.quote_balance, pair.quote_token.decimals)
                            }}</span>
                        <span>{{ pair.quote_token.symbol }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="balances-trader-wallets-container">
            <div class="balances-trader-wallets-header">
                <span class="balances-sub-title">Trader wallets</span>
                <div>
                    <button v-if="traderDeleteList.length > 0" @click="modalsStatus.tradersDeleteConfirmation = true">
                        Delete {{ traderDeleteList.length }}
                    </button>
                    <span id="delete-trader-mode-btn"
                        @click="traderDeleteMode = !traderDeleteMode; traderDeleteList = []"></span>
                    <span id="create-trader-wallet-btn" @click="modalsStatus.newTrader = true">+</span>
                </div>

            </div>
            <div v-if="balances.traders" class="balances-trader-wallets-list">
                <div v-for="(wallet, index) in balances.traders" :key="wallet.id" class="balances-trader-wallet-row"
                    @click="selectedTraderIndex = index; modalsStatus.trader = true">
                    <div class="balances-trader-wallet-left">
                        <input type="checkbox" @click.stop="toggleDeleteSelection(wallet.id)" v-if="traderDeleteMode">
                        <span>{{ contractShortener(wallet.address) }}</span>
                        <div>
                            <span>{{ wallet.trades_amount }} swaps</span>
                        </div>
                    </div>
                    <div class="balances-trader-wallet-balance-right">
                        <span>{{ balanceCorrector(wallet.native_balance, 18) }}</span>
                        <span>{{ pair.swapper.network.native_symbol }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalTransfer v-if="modalsStatus.transfer" :pair="pair" :balances="balances"
        @close="modalsStatus.transfer = false" />
    <ModalNewTrader v-if="modalsStatus.newTrader" :pair="pair" :balances="balances"
        @close="modalsStatus.newTrader = false" />
    <ModalPrimaryWallet v-if="modalsStatus.primaryWallet" :pair="pair" :primaryWallet="balances.primary_wallet"
        @close="modalsStatus.primaryWallet = false;" @refresh="fetchBalances" />
    <ModalManager v-if="modalsStatus.manager" :pair="pair" :manager="balances.manager"
        @close="modalsStatus.manager = false" @refresh="fetchBalances" />
    <ModalTrader v-if="modalsStatus.trader" :pair="pair" :trader="balances.traders[selectedTraderIndex]"
        @close="modalsStatus.trader = false" @refresh="fetchBalances" />
    <ConfirmationModal v-if="modalsStatus.tradersDeleteConfirmation"
        :warning="`You sure? All balances from ${traderDeleteList.length} traders will be transfered to Manager`"
        @no="modalsStatus.tradersDeleteConfirmation = false" @close="modalsStatus.tradersDeleteConfirmation = false"
        @yes="deleteTraders" />
</template>

<script>
import axios from "axios";
import ModalTransfer from "./ModalTransfer.vue";
import ModalNewTrader from "./ModalNewTrader.vue";
import ModalPrimaryWallet from "./ModalPrimaryWallet.vue";
import ModalManager from "./ModalManager.vue";
import ModalTrader from "./ModalTrader.vue";
import LoadingCircle from "./../../common/Loader.vue"
import ConfirmationModal from "../../ConfirmationModal.vue"

export default {
    name: "WalletsContainer",
    props: {
        pair: {
            type: Object,
            required: true,
        },
    },
    components: {
        ModalTransfer,
        ModalNewTrader,
        ModalPrimaryWallet,
        ModalManager,
        ModalTrader,
        LoadingCircle,
        ConfirmationModal
    },
    data() {
        return {
            isLoaded: false,
            isRefreshing: false,

            modalsStatus: {
                transfer: false,
                newTrader: false,
                primaryWallet: false,
                trader: false,
                manager: false,
                tradersDeleteConfirmation: false,
            },

            selectedTraderIndex: null,

            traderDeleteMode: false,
            traderDeleteList: [],


            balances: {
                primary_wallet: null,
                manager: null,
                traders: [],
            }
        };
    },
    async created() {
        await this.fetchBalances();
        this.isLoaded = true;
    },
    methods: {
        contractShortener(contract) {
            return contract.substr(0, 4) + ".." + contract.substr(-3);
        },
        balanceCorrector(value, decimals) {
            return (parseFloat(value) / Math.pow(10, decimals)).toFixed(5);
        },
        toggleDeleteSelection(traderId) {
            const index = this.traderDeleteList.indexOf(traderId);
            if (index > -1) {
                this.traderDeleteList.splice(index, 1);
            } else {
                this.traderDeleteList.push(traderId);
            }
        },
        async fetchBalances() {
            if (this.isRefreshing) return;
            this.isRefreshing = true;

            try {
                const url = `pair/${this.pair.id}/balances`
                const { data } = await axios.get(url)
                this.balances = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.isRefreshing = false;
        },
        async deleteTraders() {
            this.modalsStatus.tradersDeleteConfirmation = false;
            try {
                const url = `pair/${this.pair.id}/trader`
                await axios.delete(url, { data: { trader_ids: this.traderDeleteList } })
                this.$root.displaySuccess('Request was send. Traders balance will be emptied gradually')
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.traderDeleteList = [];
            this.traderDeleteMode = false;
        }
    },
};
</script>
<style scoped>
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.balances-pm-pw-container {
    display: flex;
    justify-content: space-between;
}

#balances-refresh-btn,
#balances-transfer-btn {
    padding: 0;
    background: url("/public/icons/transfer.svg");
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

#balances-refresh-btn {
    background-image: url("/public/icons/refresh.svg");
    width: 20px;
    height: 20px;
}


.balances-pm-pw {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 47%;
    gap: 5px;
}

.balances-pm-pw-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.balances-pm-pw-header>.balances-sub-title {
    cursor: pointer;
}

.balances-pm-pw-header>.balances-sub-title:hover {
    text-decoration: underline;
}

.balances-values-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.balances-sub-title {
    font-size: 14px;
}

.balances-values-row>span {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.balances-trader-wallets-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.balances-trader-wallets-header> :nth-child(2) {
    display: flex;
    gap: 15px;
    align-items: center
}

#delete-trader-mode-btn {
    background-image: url("/public/icons/delete.svg");
    width: 15px;
    height: 15px;
    background-size: contain;
    cursor: pointer;
}

.balances-refresh-btn {
    content: url("/public/icons/refresh.svg");
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.balances-trader-wallets-container {
    border-top: 1px solid black;
    padding-top: 10px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#create-trader-wallet-btn {
    cursor: pointer;
}

.balances-trader-wallets-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 300px;
    overflow: scroll;
    font-size: 14px;
}

.balances-trader-wallet-row {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.balances-trader-wallet-row:hover {
    background-color: aliceblue;
}

.balances-trader-wallet-left {
    display: flex;
    gap: 20px;
}

.balances-trader-wallet-left>div {
    font-size: 12px;
    margin-right: 5px;
    color: rgb(138, 138, 138)
}

.balances-trader-wallet-balance-right {
    display: flex;
    gap: 5px;
}

.is-spinning {
    opacity: 0.5;
    cursor: not-allowed;
    animation: spin 1s linear infinite;
}
</style>
