<template>
    <ModalWindow :title="'Primary Wallet'" @close="$emit('close')">
        <div class='modal-section'>
            <span class="modal-section-title">Address</span>
            <div> {{ primaryWallet.address }} </div>
            <button class="open-in-explorer-btn" @click="openInExplorer">Open in explorer</button>
        </div>
        <div class="modal-section">
            <span class="modal-section-title">Balances</span>
            <div class='balances-container'>
                <div class='balances-row'>
                    <span>
                        {{ balanceCorrector(primaryWallet.native_balance, 18) }}
                    </span>
                    <span>
                        {{ pair.swapper.network.native_symbol }}
                    </span>
                </div>
                <div class='balances-row'>
                    <span>
                        {{ balanceCorrector(primaryWallet.base_balance, pair.base_token.decimals) }}
                    </span>
                    <span>
                        {{ pair.base_token.symbol }}
                    </span>
                </div>
                <div class='balances-row'>
                    <span>
                        {{ balanceCorrector(primaryWallet.quote_balance, pair.quote_token.decimals) }}
                    </span>
                    <span>
                        {{ pair.quote_token.symbol }}
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button :disabled="waiting.blockchainRefresh" @click="blockchainRefresh">Refresh from blockchain</button>
            <input v-model="topUpHash" :disabled="waiting.blockchainRefresh" placeholder="Veryfy top up. Put TXN hash here..." @input="verifyTopUp">
        </div>
    </ModalWindow>
</template>
<script>
import axios from 'axios';
import ModalWindow from '../../common/Modal.vue'

export default {
    name: "ModalPrimaryWallet",
    emits: ['close', 'refresh'],
    components: { ModalWindow },
    props: {
        pair: {
            type: Object,
            required: true,
        },
        primaryWallet: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            waiting: {
                blockchainRefresh: false,
                topUpCheck: false,
            },

            topUpHash: null,
        }
    },
    methods: {
        balanceCorrector(value, decimals) {
            return parseFloat(value) / (10 ** decimals);
        },
        openInExplorer() {
            const url = this.pair.swapper.network.explorer_url + 'address/' + this.primaryWallet.address;
            window.open(url, '_blank')
        },
        async blockchainRefresh() {
            if (this.waiting.blockchainRefresh) return;
            this.waiting.blockchainRefresh = true;

            try {
                const url = `pair/${this.pair.id}/primary-wallet/refresh`
                const { data } = await axios.post(url);
                if (data.data) {
                    this.$root.displaySuccess('Successfully refreshed')
                    this.$emit('refresh')
                } else {
                    this.$root.displaySuccess('Unable to fetch the result of execution');
                }
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.waiting.blockchainRefresh = false;
        },
        async verifyTopUp() {
            if (this.waiting.topUpCheck) return;
            if (this.topUpHash.length !== 66) return;
            this.waiting.topUpCheck = true;

            try {
                const url = `pair/${this.pair.id}/primary-wallet/check-top-ups`
                const { data } = await axios.post(url, {txn_hash: this.topUpHash});
                this.$root.displaySuccess(data);
                this.$emit('refresh');
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.waiting.topUpCheck = false;
            this.topUpHash = null;
        },
    }
}
</script>
<style scoped>
.balances-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.balances-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.balances-row {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
.modal-footer{
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.modal-footer > button,
.modal-footer > input{
    display: block;
    width: 100%;
    padding: 6px;
    border: 1px solid #bebebe;
}
.modal-footer > button:disabled,
.modal-footer > input:disabled{
    cursor: progress;
}
</style>