<template>
    <ModalWindow @close="$emit('close')" :title="'New member'" :width="'350px'">
        <div class="new-member-form">
            <input class="cs-input" :class="{'non-empty': data.firstName.length > 0}" placeholder="First name" v-model="data.firstName">
            <input class="cs-input" :class="{'non-empty': data.lastName.length > 0}" placeholder="Last name" v-model="data.lastName">
            <input class="cs-input" :class="{'non-empty': data.email.length > 0}" placeholder="Email" v-model="data.email">
            <select class="cs-select non-empty" v-model="data.accessLevel">
                <option value="3">Admin</option>
                <option value="2">Manager</option>
                <option value="1">User</option>
            </select>
            <button class="cs-button next" :disabled="isPosting || !isAllowed" @click="createUser">Create</button>
        </div>
        <div class="received-password" v-if="receivedPassword">
            <span>Password: </span>
            <span id="password">{{ receivedPassword }}</span>
        </div>
    </ModalWindow>
</template>
<script>
import axios from 'axios'
import ModalWindow from '../../common/Modal.vue'

export default {
    name: 'MemberNewModal',
    components: { ModalWindow },
    emits: ['close', 'refresh'],
    data() {
        return {
            isPosting: false,
            data: {
                firstName: "",
                lastName: "",
                accessLevel: "1",
                email: "",
            },
            receivedPassword: null,
        }
    },
    computed: {
        isAllowed() {
            return (this.data.firstName && this.data.lastName && this.data.email)
        }
    },
    methods: {
        async createUser() {
            this.isPosting = true;
            try {
                const url = `organization/members`
                const { data } = await axios.post(url, {
                    first_name: this.data.firstName,
                    last_name: this.data.lastName,
                    email: this.data.email,
                    access_level: this.data.accessLevel,
                })
                this.receivedPassword = data.password
                this.$root.displaySuccess(`Success`)
                this.$emit('refresh')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
            this.isPosting = false;
        },
    },
}
</script>
<style scoped>
.new-member-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.received-password{
    margin-top: 15px;
    font-size: 12px;
}
#password{
    background-color: #eee;
    padding: 3px 6px;
}
</style>