<template>
    <LoadingCircle v-if="!logs"/>
    <div class="strategy-logs" v-else>
        <div v-for="log in logs" :key="log.id" class="strategy-log-row">
            <span class="created-at">{{ log.created_at.replace("T", " ").slice(0, -3) }}</span>
            <span class="event-name" :class="log.level.toLowerCase()">{{ log.event }}</span>
            <span v-if="log.data" class="data">
                <div class="data-row" v-for="(value, key) in log.data" :key="key">
                    <span>{{ key }}</span>
                    <span>{{ JSON.stringify(value, null, 2) }}</span>
                </div>
            </span>
        </div>
    </div>
    <div class="strategy-logs-tools">
        <button @click="$emit('loadLogs', {offset: logs.length, limit: settings.rows, more: true})">Load more</button>
        <div class="separator"></div>
        <span>
            <p>Rows</p>
            <select v-model="settings.rows">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>
        </span>
        <span>
            <p>Autorefresh</p>
            <select v-model="settings.autoRefreshInterval">
                <option value="0">Off</option>
                <option value="5000">5s</option>
                <option value="10000">10s</option>
                <option value="30000">30s</option>
                <option value="60000">1m</option>
            </select>
        </span>
    </div>
</template>
<script>
import LoadingCircle from "@/components/common/Loader.vue"
export default {
    name: "LogsComponent",
    components: { LoadingCircle },
    props: {
        logs: {
            type: Array,
            required: false,
        },
    },
    emits: ["loadLogs"],
    data() {
        return {
            settings: {
                autoRefreshInterval: '0',
                rows: "10",
            },
            timer: null,
        }
    },
    watch: {
        'settings.autoRefreshInterval'(newInterval) {
            this.setAutorefresh(newInterval);
        },
    },
    methods: {
        setAutorefresh(interval) {
            clearInterval(this.timer);
            if (interval > 0) {
                this.timer = setInterval(() => {
                    this.$emit('loadLogs', { offset: 0, limit: this.settings.rows })
                }, interval);
            }
        }
    },
    mounted() {
        if (this.logs) return;
        this.$emit('loadLogs', { offset: 0, limit: this.settings.rows })
    },
    beforeUnmount() {
        clearInterval(this.timer);
    },
}
</script>
<style scoped>
.strategy-logs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
}

.strategy-log-row {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 12px;
}

.strategy-log-row>.event-name {
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 5px;
    background-color: #BBF6FE;
}

.strategy-log-row>.data {
    display: flex;
    gap: 3px;
    flex-direction: column;
    border-radius: 5px;
    padding: 4px 8px;
    background-color: #eee;
    max-width: 800px;
}

.strategy-log-row>.data>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.strategy-log-row>.data>div> :first-child {
    font-weight: 700
}

.strategy-logs-tools {
    font-size: 12px;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 20px;
    right: 20px;
    opacity: 0.3;
    z-index: 4;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #CECECE;
}

.strategy-logs-tools:hover {
    opacity: 1;
}

.strategy-logs-tools>button {
    border-radius: 2px;
}

.strategy-logs-tools>.separator {
    height: 20px;
    width: 2px;
    margin-right: 8px;
    background-color: #eee;
}

.strategy-logs-tools>span {
    display: flex;
    align-items: center;
    gap: 5px;
}

.strategy-logs-tools>span>select {
    padding: 3px 6px;
    border: 1px solid #CECECE;
    border-radius: 5px;
    font-size: 12px;
}
.event-name.info{
    background-color: rgb(191, 243, 255);
}
.event-name.service{
    background-color: rgb(199, 255, 230);
}
.event-name.warning{
    background-color: rgb(255, 251, 145);
}
.event-name.error{
    background-color: rgb(255, 125, 125);
}
.event-name.trade{
    background-color: rgb(242, 166, 255);
}
</style>