<template>
    <div>
        <div class="cs-header small">Members</div>
        <LoadingCircle v-if="!members" />
        <div class="members-list" v-else>
            <div class="column-headers">
                <span class="column-header column-username">Name</span>
                <span class="column-header column-email">Email</span>
                <span class="column-header column-level">Level</span>
                <span class="column-header column-enabled">Blocked</span>
            </div>
            <span class="member-row" v-for="member in members" :key="member.id" @click="openModalEdit(member)">
                <span class="column-username column-value">{{ member.first_name }} {{ member.last_name }}</span>
                <span class="column-email column-value">{{ member.email }}</span>
                <span class="column-level column-value">{{ accessLevels[member.access_level] }}</span>
                <span class="column-enabled column-value">{{ member.is_disabled ? "True" : "False" }}</span>
            </span>
        </div>
        <button @click="isModalNewOpen=true" class="cs-button small">New member</button>
        <MemberEditModal :member="modalMember" v-if="isModalOpen" @close="modalMember=null; isModalOpen=false;" @refresh="fetchMembers"/>
        <MemberCreateModal v-if="isModalNewOpen" @close="isModalNewOpen=false" @refresh="fetchMembers"/>
    </div>
</template>
<script>
import axios from "axios";
import LoadingCircle from "../../common/Loader.vue"
import MemberEditModal from "./EditModal.vue"
import MemberCreateModal from "./CreateModal.vue"

export default {
    name: 'MembersSection',
    components: { LoadingCircle, MemberEditModal, MemberCreateModal },
    props: {
        organizationId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            members: null,
            isModalOpen: false,
            isModalNewOpen: false,
            modalMember: null,
            accessLevels: {
                3: 'Admin',
                2: 'Manager',
                1: 'User',
            }
        }
    },
    async created() {
        await this.fetchMembers()
    },
    methods: {
        async fetchMembers() {
            try {
                const { data } = await axios.get('/organization/members');
                this.members = data
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        openModalEdit(member) {
            this.modalMember = member;
            this.isModalOpen = true;
        }
    },
}
</script>
<style scoped>
.member-row{
    display: flex;
}
.member-row:hover{
    background-color: aliceblue;
    cursor: pointer;
}
.members-list{
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 20px;
}
.members-list > div > span {
    display: inline-block;
}
.column-headers{
    margin-bottom: 5px;
}
.column-header{
    font-size: 12px !important;
    font-weight: 700;
}
.column-value{
    font-size: 14px;
}
.column-username{
    width: 240px;
}
.column-email{
    width: 240px;
}
.column-level{
    width: 100px;
}
.column-enabled{
    width: 70px;
}
/* #new-member-btn{
    opacity: 0.4;
    padding: 4px;
    margin-top: 10px;
    display: block;
    width: 100%;
}
#new-member-btn:hover{
    opacity: 1;
} */
</style>