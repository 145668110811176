<template>
    <div class="cs">
        <div class="cs-header">
            Almost there! Please set a password
        </div>
        <div class="cs-content">
            <div class="cs-tools">
                <input class="cs-input" type="password" v-model="password" :class="{ 'non-empty': password.length > 0 }"
                    placeholder="Password">
                    <button class="cs-button next" :disabled="!isPasswordStrong" @click="setPassword">Finish</button>
            </div>
            <div class="cs-tools">
                <div class="password-rules">
                    <span :class="{ passed: checkLenght }">At least 12 symbols</span>
                    <span :class="{ passed: checkDigits }">At least 2 digits</span>
                    <span :class="{ passed: checkUpper }">At least 2 uppercase letters</span>
                    <span :class="{ passed: checkPunct }">At least 1 punctuation char</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "SetPassword",
    emits: ['updContext', 'next'],
    data() {
        return {
            password: "",
        }
    },
    computed: {
        checkLenght() {
            return this.password.length >= 12
        },
        checkDigits() {
            return (this.password.match(/\d/g) || []).length >= 2
        },
        checkUpper() {
            return (this.password.match(/[A-Z]/g) || []).length >= 2
        },
        checkPunct() {
            return (this.password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []).length >= 1
        },
        isPasswordStrong() {
            return this.checkLenght && this.checkDigits && this.checkPunct && this.checkUpper
        }
    },
    methods: {
        async setPassword() {
            try {
                const url = `registration/finish`;
                await axios.post(url, { password: this.password }, { withCredentials: true })
                this.$router.push("/login")
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
    },
}
</script>
<style scoped>
.cs-tools {
    display: flex;
    justify-content: space-between;
}

.cs-tools>input {
    margin-left: -2px;
    width: 300px;
}

.cs-tools>span {
    color: #a0a0a0;
}

.password-rules {
    margin-top: 10px;
    margin-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
}

.password-rules>.passed {
    color: #05C023;
}
</style>