<template>
    <div class="strategies-container">
        <div v-if="!addingStrategy">
            <div class="strategies-header">
                <div class="strategies-header-title">Strategies</div>
                <button class="strategies-add-button plus" @click="openAddStrategyMenu"></button>
            </div>
            <div class="strategies-tab-switcher">
                <span :class="{'switcher-active': currentTab === 'active'}" @click="currentTab='active'">Active</span>
                <span :class="{'switcher-active': currentTab === 'finished'}" @click="currentTab='finished'">Finished</span>
            </div>
            <div v-if="!strategies.active && !strategies.finished" class="loader-holder">
                <div class="loader"></div>
            </div>
            <div v-if="currentTab === 'active'" class="strategies-list">
                <StrategyRow
                :strategy="strategy"
                :pair="pair"
                :reserves="reserves"
                @updated="fetchStrategies"
                v-for="strategy in strategies.active"
                :key="strategy.id"/>
            </div>
            <div v-if="currentTab === 'finished'" class="strategies-list">
                <StrategyRow
                :strategy="strategy"
                :pair="pair"
                :reserves="reserves"
                @updated="fetchStrategies"
                v-for="strategy in strategies.finished"
                :key="strategy.id"/>
            </div>
        </div>
        <div v-else>
            <div class="strategies-header">
                <div class="strategies-header-title">Add strategy</div>
                <button class="strategies-add-button minus" @click="closeAddStrategyMenu"></button>
            </div>
            <div class="add-strategies-list">
                <div @click="openAddSimpleStrategyModal" class="add-strategies-option">Strategy Simple</div>
                <div class="add-strategies-option">Strategy Composition</div>
                <div class="add-strategies-option">Strategy Sequence</div>
            </div>
        </div>
    </div>
    <div v-if="isAddSimpleStrategyOpen" class="modal">
        <div class="modal-content huge-modal">
            <AddBaseStrategy :pair="pair" :reserves="reserves" @close="closeAddSimpleStrategy" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import AddBaseStrategy from "./AddBaseStrategyModal.vue"
import StrategyRow from "./StrategyRow.vue";

export default {
    name: "StrategiesContainer",
    props: {
        pair: {
            type: Object,
            required: true,
        },
        reserves: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            strategies: {
                active: null,
                finished: null
            },
            currentTab: 'active',
            addingStrategy: false,
            isAddSimpleStrategyOpen: false,
        };
    },
    components: {
        AddBaseStrategy, StrategyRow
    },
    async created() {
        await this.fetchStrategies();
    },
    methods: {
        closeAddSimpleStrategy() {
            this.isAddSimpleStrategyOpen = false;
        },
        async fetchStrategies() {
            try {
                const url = `pair/${this.pair.id}/strategies`
                const { data } = await axios.get(url);
                this.strategies.active = [];
                this.strategies.finished = [];
                data.forEach(strategy => {
                    if (strategy.status === 'Finished') {
                        this.strategies.finished.push(strategy);
                    } else {
                        this.strategies.active.push(strategy);
                    }
                });
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        openAddStrategyMenu() {
            this.addingStrategy = true;
        },
        closeAddStrategyMenu() {
            this.addingStrategy = false;
        },
        openAddSimpleStrategyModal() {
            this.isAddSimpleStrategyOpen = true;
        },
    },
};
</script>

<style scoped>
.strategies-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.add-strategies-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-strategies-option {
    padding: 5px 10px;
    cursor: pointer;
}

.add-strategies-option:hover {
    background-color: aliceblue;
}
.strategies-tab-switcher{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}
.strategies-tab-switcher > span{
    font-size: 14px;
    color: #bebebe;
    cursor: pointer;
}
.switcher-active{
    color: black !important;
    cursor: default !important;
}

.strategies-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    height: 400px;
}

.strategy-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.strategies-add-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 18px;
    height: 18px;
    background-size: cover;
    padding: 0;
}

.strategies-add-button.plus {
    background-image: url('/public/icons/add.svg');
}

.strategies-add-button.minus {
    background-image: url('/public/icons/add_minus.svg');
}
</style>
