<template>
    <div :class="{'quick-action-disabled': !isActive}">
        <div class='quick-action-header'>
            <span>Swap</span>
        </div>
        <div class="quick-action-params">
            <div class="quick-action-cell">
                <p>amount</p>
                <input v-model="amountStr.value" class="from-value" type="number" @input="validateAmount"
                    :class="{ error: !amountStr.isValid}">
            </div>
            <div class="quick-action-cell">
                <p>from</p>
                <select v-model="fromType">
                    <option value='base'>{{ baseToken.symbol }}</option>
                    <option value="quote">{{ quoteToken.symbol }}</option>
                </select>
            </div>
            <div class="quick-action-cell">
                <p>to</p>
                <span v-if="fromType === 'base'" class="to-value">{{ quoteToken.symbol }}</span>
                <span v-else class="to-value">{{ baseToken.symbol }}</span>
            </div>
            <div class="quick-action-cell">
                <p>% slip</p>
                <input v-model="slippageStr.value" class="slippage-value" placeholder="%" type="number" @input="validateSlippage"
                    :class="{ error: !slippageStr.isValid }">
            </div>
        </div>
        <div class="estimation-container">
            <div v-if="amountOut" class="estimation">
                <div>
                    <p>receive</p>
                    <b>{{ round(amountOut, 6) }}</b>
                    <b v-if="fromType === 'base'">{{ quoteToken.symbol }}</b>
                    <b v-else>{{ baseToken.symbol }}</b>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MakeSwap",
    emits: ["tradeReady"],
    props: {
        isActive: {
            type: Boolean,
            required: true,
            default: false,
        },
        baseToken: {
            type: Object,
            required: true
        },
        quoteToken: {
            type: Object,
            required: true
        },
        reserves: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            amountStr: {
                value: '0',
                isValid: false,
            },
            slippageStr: {
                value: '5',
                isValid: true,
            },
            fromType: 'base',
        }
    },
    computed: {
        reserveFrom() {
            return this.fromType === 'base' ? this.reserves[0] : this.reserves[1];
        },
        reserveTo() {
            return this.fromType === 'base' ? this.reserves[1] : this.reserves[0];
        },
        amountOut() {
            if (this.amountStr.isValid && this.slippageStr.isValid) {
                const amount = parseFloat(this.amountStr.value)
                const numerator = amount * this.reserveTo;
                const denominator = this.reserveFrom + amount;
                const amountOut = numerator / denominator;
                return amountOut
            } else {
                return null;
            }
        },
    },
    watch: {
        amountOut(newVal, ) {
            if (typeof newVal === 'number' && newVal > 0) {
                const slippage = parseFloat(this.slippageStr.value)
                const amount = parseFloat(this.amountStr.value)
                const tradeData = {
                    from: this.fromType === 'base' ? 'base' : 'quote',
                    to: this.fromType === 'base' ? 'quote' : 'base',
                    amountIn: amount,
                    amountOutMin: parseFloat(newVal * (1 - (slippage / 100))),
                };
                this.$emit('tradeReady', tradeData);
            } else {
                this.$emit('tradeReady', null);
            }
        }
    },
    methods: {
        validateAmount(event) {
            const value  = parseFloat(event.target.value)
            if (isNaN(value) || value <= 0 || value >= 999999999999999) {
                this.amountStr.isValid = false;
                return
            }
            this.amountStr.isValid = true;
            this.amountStr.value = value.toString()
        },
        validateSlippage(event) {
            const value  = parseFloat(event.target.value)
            if (isNaN(value) || value < 0 || value > 50) {
                this.slippageStr.isValid = false;
                return
            }
            this.slippageStr.isValid = true;
            this.slippageStr.value = value.toString();
        },
        round(value, digits) {
            return parseInt(value * (10 ** digits)) / (10 ** digits);
        },
    }
}
</script>
<style scoped>
select,
input,
.to-value {
    padding: 3px 5px;
    border: 1px solid #000000;
    border-radius: 5px;
}

select {
    width: 70px;
    text-align: center;
}

input {
    width: 60px;
    text-align: right;
}

.from-value {
    width: 100px;
}

.to-value {
    font-size: 14px;
    width: 50px;
    text-align: center;
    padding: 3px 5px;
}

.slippage-value {
    width: 50px;
}
</style>