<template>
    <div class="login-page">
        <PreLogin v-if="page === 'prelogin'" :context="context" @upd-context="updateContext" @twofa="page='twofa'" @passrec="page='passrec'"/>
        <TwoFactor v-if="page === 'twofa'" :context="context" @upd-context="updateContext"/>
        <PasswordRecovery v-if="page === 'passrec'" @upd-context="updateContext" @prelogin="page='prelogin'"/>
    </div>
</template>
<script>
import PreLogin from "./__PreLogin.vue";
import TwoFactor from "./__TwoFactor.vue"
import PasswordRecovery from "./__PasswordRecovery.vue"

export default {
    name: "LoginPage",
    components: { PreLogin, TwoFactor, PasswordRecovery },
    data() {
        return {
            context: {
                username: "",
            },
            page: 'prelogin',
        }
    },
    methods: {
        updateContext(newCntx) {
            if (!this.context) {
                this.context = newCntx
            }
            this.context = {...this.context, ...newCntx}
        }
    },
}
</script>
<style>
.login-page {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
</style>