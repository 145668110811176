<template>
    <ModalWindow @close="$emit('close')" :title="'Add pair'" :width="'600px'">
    <div class="add-pair-container">
        <div class="add-pair-inputs">
            <select class="cs-select" :class="{'non-empty': selectedSwapperId}" v-model="selectedSwapperId">
                <option v-for="swapper in swappersList" :key="swapper.id" :value="swapper.id">
                    {{ swapper.title }}
                </option>
            </select>
            <input class="cs-input" :class="{'non-empty': insertedContract.length > 0}" v-model="insertedContract" placeholder="Insert contract">
            <button class="cs-button next" @click="findPair">Search</button>
        </div>
        <div v-if="pairData" class="add-pair-data">
            <div class="add-pair-data-row">
                <span class="add-pair-data-value">{{ pairData.token0.symbol }}</span>
                <span class="add-pair-data-title">Symbol</span>
                <span class="add-pair-data-value right">{{ pairData.token1.symbol }}</span>
            </div>
            <div class="add-pair-data-row">
                <span class="add-pair-data-value">{{ contractShortener(pairData.token0.contract) }}</span>
                <span class="add-pair-data-title">Contract</span>
                <span class="add-pair-data-value right">{{ contractShortener(pairData.token1.contract) }}</span>
            </div>
            <div class="add-pair-data-row">
                <span class="add-pair-data-value">{{ pairData.token0.decimals }}</span>
                <span class="add-pair-data-title">Decimals</span>
                <span class="add-pair-data-value right">{{ pairData.token1.decimals }}</span>
            </div>
            <div class="add-pair-data-row">
                <span class="add-pair-data-value"><input type="radio" v-model="baseTokenMark" value="0" /></span>
                <span class="add-pair-data-title">Base token</span>
                <span class="add-pair-data-value right"><input type="radio" v-model="baseTokenMark" value="1" /></span>
            </div>
        </div>
        <div v-if="pairData" class="add-pair-btn">
            <button class="cs-button next" @click="addPair">Add</button>
        </div>
    </div>
    </ModalWindow>
</template>
<script>
import axios from 'axios';
import ModalWindow from '../common/Modal.vue';

export default {
    name: "ModalAddPair",
    emits: ['pair-added', 'close'],
    components: { ModalWindow },
    data() {
        return {
            insertedContract: "",
            selectedSwapperId: null,
            swappersList: null,
            pairData: null,
            baseTokenMark: 0,
        };
    },
    computed: {
        isValidContract() {
            return this.insertedContract.length === 42 && this.selectedSwapperId;
        },
    },
    async mounted() {
        await this.fetchSwappers();
    },
    methods: {
        async fetchSwappers() {
            try {
                const { data } = await axios.get('swapper');
                this.swappersList = data;
                this.selectedSwapperId = this.swappersList[0].id;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async findPair() {
            if (!this.isValidContract) return;

            try {
                const { data } = await axios.get(`swapper/${this.selectedSwapperId}/find-pair`, {
                    params: {
                        contract: this.insertedContract,
                    }
                });
                this.pairData = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async addPair() {
            try {
                await axios.post('pair', {
                    contract: this.insertedContract,
                    swapper_id: this.selectedSwapperId,
                    base_token_mark: this.baseTokenMark
                });
                this.$emit('pair-added');
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        contractShortener(value) {
            if (!value || value.length < 7) return value;
            return `${value.substr(0, 4)}..${value.substr(-3)}`;
        },
    }
};
</script>
<style scoped>
.add-pair-inputs {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between
}

.add-pair-data {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.add-pair-data-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.add-pair-data-title {
    color: #bababa;
}

.add-pair-data-value {
    width: 150px;
}

.add-pair-data-value {
    text-align: center;
}

.add-pair-btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
</style>