<template>
    <div class="process-container">
        <div v-if="step === 0" class="process-button-container">
            <button :disabled="!tradeData" @click="prepareTrade">Next</button>
        </div>
        <div v-if="step === 1" class="process-select-trader">
            <div v-if="!estimatedData" class="process-searching-traders">
                Searching for traders...
            </div>
            <div v-else class="process-select-traders">
                <div>
                    <p>{{ estimatedData.price }} {{ pair.swapper.network.native_symbol }}</p>
                    <select v-model="selectedTrader">
                        <option v-for="trader in estimatedData.traders" :key="trader.id" :value="trader">
                            {{ contractShortener(trader.address) }},
                            {{ (trader.native_balance / (10 ** 18)).toFixed(5) }} {{ pair.swapper.network.native_symbol}}
                        </option>
                    </select>
                </div>
                <button @click="step = 2">Next</button>
            </div>
        </div>
        <div v-if="step === 2" class="process-swap-data-container">
            <div>
                <div>
                    <p>From:</p>
                    <b>
                        {{ tradeData.amountIn.toFixed(6) }}
                        {{ tokenMapping[tradeData.from].symbol }}
                    </b>
                </div>
                <div>
                    <p>To min:</p>
                    <b>
                        {{ tradeData.amountOutMin.toFixed(6) }}
                        {{ tokenMapping[tradeData.to].symbol }}
                    </b>
                </div>
                <div>
                    <p>Trader:</p>
                    <b> {{ contractShortener(selectedTrader.address) }}</b>
                </div>
            </div>
            <button @click="executeTrade" :disabled="isExecuting">Execute</button>
            <VerificationModal v-if="verification.open" @success="verification.done=true;"
                @close="verification.open=false" />
        </div>


    </div>
</template>
<script>
import axios from 'axios';
import VerificationModal from '../../VerificationModal.vue';

export default {
    name: "ProcessContainer",
    components: { VerificationModal },
    props: {
        tradeData: {
            type: Object,
            required: false,
        },
        pair: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            step: 0,
            tokenMapping: {
                'base': this.pair.base_token,
                'quote': this.pair.quote_token,
            },
            estimatedData: null,
            selectedTrader: null,

            isExecuting: false,
            verification: {
                open: false,
                done: false,
            }
        }
    },
    watch: {
        tradeData() {
            this.clearTrade();
        }
    },
    methods: {
        clearTrade() {
            this.step = 0;
            this.estimatedData = null
            this.selectedTrader = null;
            this.verification = {
                open: false,
                done: false,
            },
            this.isExecuting = false;
        },
        contractShortener(value) {
            return value.substr(0, 4) + ".." + value.substr(-3);
        },
        async prepareTrade() {
            this.step = 1;

            try {
                const url = `pair/${this.pair.id}/quick-trade/find-trader`;
                const { data } = await axios.post(url, {
                    from_token: this.tradeData.from,
                    to_token: this.tradeData.to,
                    amount_given_in: this.tradeData.from,
                    amount_in: this.tradeData.amountIn,
                    amount_out_min: this.tradeData.amountOutMin,
                })

                if (!data.allowed) {
                    this.$root.displayError(`Trade not allowed: ${data.error}`)
                    this.clearTrade();
                    return
                } 

                this.estimatedData = {
                    traders: data.traders,
                    price: (data.estimated_price / (10 ** 18)).toFixed(5),
                };

                if (this.estimatedData.traders.length === 0) {
                    const nativeSymbol = this.pair.swapper.network.native_symbol
                    this.$root.displayError(`No trader found. Estmated cost: ${this.estimatedData.price} ${nativeSymbol}`);
                    this.clearTrade();
                    return
                }
                this.selectedTrader = this.estimatedData.traders[0]

            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async executeTrade() {
            if (!this.verification.done) {
                this.verification.open = true;
                return
            }
            this.isExecuting = true;
            try {
                const url = `/pair/${this.pair.id}/quick-trade/execute`
                const { data } = await axios.post(url, {
                    data: {
                        from_token: this.tradeData.from,
                        to_token: this.tradeData.to,
                        amount_given_in: this.tradeData.from,
                        amount_in: this.tradeData.amountIn,
                        amount_out_min: this.tradeData.amountOutMin,
                    },
                    trader_id: this.selectedTrader.id
                })
                if (data.data) {
                    this.$root.displaySuccess(`Trade executed with status: ${data.data.status}`);
                } else {
                    this.$root.displaySuccess('Unable to fetch the result of execution');
                }
                this.clearTrade();
            } catch (error) {
                const { status, data } = error.response;
                this.$root.displayError(data.detail);
                if (status === 403) {
                    this.verification = {done: false, open: true};
                }
            }
            this.isExecuting = false;
        },
    }
}
</script>
<style scoped>
button {
    width: 100%;
    padding: 5px 0px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: transparent;
}

button:disabled {
    background-color: rgb(239, 239, 239);
    cursor: not-allowed;
}

button:hover {
    background-color: aqua;
    border-color: aqua;
}

.process-container {
    border: 1px solid #bebebe;
    padding: 15px;
    border-radius: 5px;
}

.process-searching-traders {
    text-align: center;
    font-size: 12px;
}

.process-select-traders {
    display: flex;
    justify-content: space-between;
}

.process-select-traders>div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;
}

.process-select-traders>div>p {
    color: rgb(133, 133, 133);
    font-size: 12px;
}

.process-select-traders>div>select {
    padding: 5px;
    border: 1px solid #bebebe;
    border-radius: 5px;
}

.process-select-traders>button {
    width: 90px;
}

.process-swap-data-container {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.process-swap-data-container> :first-child {
    background-color: rgb(243, 181, 235);
    padding: 3px 6px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}

.process-swap-data-container> :first-child>div {
    display: flex;
    justify-content: space-between;
}

.process-swap-data-container> :first-child>div>p {
    width: 60px;
}

.process-swap-data-container>button {
    width: 70px;
}
.process-swap-data-container>button:disabled {
    cursor: progress;
}
</style>