<template>
    <div>
        <div class="cs-header small">Telegram</div>
        <LoadingCircle v-if="!organization" />
        <div v-if="organization.tg_channel">
            Сonnected!
        </div>
        <div v-else>
            <p>
                Add our
                <a target="_blank" href="https://t.me/bitfusion_bot?start=start">bot</a>
                 to your channel, call
                <b>/link_organization</b>.
                Then put received token in lower form
            </p>
            <div class="link-telegram">
                <input class="cs-input small" :class="{'non-empty': linkToken.length > 0}" v-model="linkToken" placeholder="Enter token" />
                <button class="cs-button small" :disabled="!linkToken.length" @click="verifyTelegramToken">Submit</button>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingCircle from "@/components/common/Loader.vue"
import axios from "axios"

export default {
    name: 'TelegramSection',
    components: { LoadingCircle },
    emits: ['refresh'],
    props: {
        organization: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            linkToken: "",
        }
    },  
    methods: {
        async verifyTelegramToken() {
            try {
                await axios.post("/organization/link-telegram", { token: this.linkToken })                
                this.$root.displaySuccess("Telegram successfully linked")
                this.$emit('refresh')
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
    }
}
</script>
<style scoped>
p{
    max-width: 300px;
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
}
.link-telegram{
    display: flex;
    gap: 5px;
}
</style>