<template>
    <div class="register-page">
        <InvitationToken
        v-if="page === 0"
        @next="page++"
        @back="page--"
        @updContext="updateContext"
        />
        <IntroduceYourself
        v-if="page === 1" 
        :context="context"
        @next="page++"
        @back="page--"
        @updContext="updateContext"
        />
        <VerifyEmail
        v-if="page === 2"
        :context="context"
        @next="page++"
        @back="page--"
        @updContext="updateContext"
        />
        <SetPassword
        v-if="page === 3"
        :context="context"
        @next="page++"
        @updContext="updateContext"
        />
    </div>
</template>
<script>
import InvitationToken from "./__InvitationToken.vue"
import IntroduceYourself from "./__IntroduceYourself.vue"
import VerifyEmail from "./__VerifyEmail.vue"
import SetPassword from "./__SetPassword.vue"
import axios from "axios"

export default {
    name: "RegisterPage",
    components: { InvitationToken, IntroduceYourself, VerifyEmail, SetPassword },
    data() {
        return {
            context: null,
            page: 0,
        }
    },
    async created() {
        await this.fetchContext();
    },
    methods: {
        async fetchContext() {
            try {
                const url = "registration/get-context"
                const { data } = await axios.get(url, {withCredentials: true})
                this.context = data;
                if (!this.context) {
                    this.page = 0
                } else if (this.context.emailConfirmed) {
                    this.page = 3
                } else  {
                    this.page = 1
                }
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        updateContext(newCntx) {
            if (!this.context) {
                this.context = newCntx
            }
            this.context = {...this.context, ...newCntx}
        }
    },
}
</script>
<style>
.register-page {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
</style>