<template>
    <ModalWindow :title="'Transaction'" @close="$emit('close')" :width="'700px'">
        <LoaderCircle v-if="!transaction"/>
        <div v-else>
            <div class='modal-section'>
                <span class="modal-section-title">Hash</span>
                <div class="hash">{{ transaction.hash }}</div>
                <button class="open-in-explorer-btn" @click="openInExporer">
                    see in exporer
                </button>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Type</span>
                <span>{{ transaction.type }}</span>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Created at</span>
                <span>{{ formatDateTime(transaction.created_at) }}</span>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Ended at</span>
                <span>{{ formatDateTime(transaction.updated_at) }}</span>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Status</span>
                <div>
                    <span class="status" :class="{
                        send: transaction.status === 'Send',
                        success: transaction.status === 'Success',
                        fail: transaction.status === 'Fail',
                        notexecuted: transaction.status === 'NotExecuted'
                    }">
                        {{ transaction.status }}
                    </span>
                </div>

            </div>
            <div class='modal-section'>
                <span class="modal-section-title">
                    <b>Response</b>
                </span>
                <div class="response">
                    {{ transaction.response }}
                </div>
            </div>
        </div>
    </ModalWindow>
</template>
<script>
import axios from 'axios';
import LoaderCircle from '../../common/Loader.vue'
import ModalWindow from '../../common/Modal.vue';

export default {
    name: "TransactionModal",
    components: {LoaderCircle, ModalWindow},
    props: {
        transactionId: {
            type: String,
            required: true,
        },
        pairId: {
            type: String,
            required: true,
        },
        explorerUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            transaction: null
        }
    },
    async created() {
        await this.fetchTransaction();
    },
    methods: {
        async fetchTransaction() {
            try {
                const url = `pair/${this.pairId}/transactions/${this.transactionId}`
                const { data } = await axios.get(url);
                this.transaction = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        openInExporer() {
            const url = this.explorerUrl + 'tx/' + this.transaction.hash;
            window.open(url, '_blank')
        },
        formatDateTime(date) {
            const datetime = new Date(date);
            const today = new Date();

            const dateStr = datetime.toLocaleDateString();
            const timeStr = datetime.toLocaleTimeString();
            if (
                datetime.getDate() === today.getDate() &&
                datetime.getMonth() === today.getMonth() &&
                datetime.getFullYear() === today.getFullYear()
            ) {
                return datetime.toLocaleTimeString();
            } else {
                return `${dateStr.slice(0, -5)} ${timeStr}`;
            }
        }
    }
}
</script>
<style scoped>
.response {
    max-height: 300px;
    padding: 10px;
    border-radius: 20px;
    overflow-y: scroll;
    background-color: aliceblue;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 14px;
    box-shadow: inset 0 0 15px #0000002e;
}

.status {
    padding: 3px 5px;
    border-radius: 10px;
}

.status.send {
    background-color: rgb(255, 255, 220);
}

.status.success {
    background-color: rgb(225, 252, 231);
}

.status.fail {
    background-color: rgb(255, 226, 226);
}

.status.notexecuted {
    background-color: rgb(224, 224, 224);
}

.txn-success {
    background-color: rgb(103, 234, 103);
}

.txn-failed {
    background-color: rgb(255, 115, 115);
}

.txn-processing {
    background-color: rgb(188, 188, 188);
    padding: 3px 7px;
}
</style>