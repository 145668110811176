<template>
    <div class="settings" :class="{'uneditable': strategy.status === 'Finished'}">
        <div class="sliders">
            <input type="range" orient="vertical" v-model="slotsLoad" min="0" max="100">
            <span>Slots load</span>
            <span>{{ slotsLoad }} %</span>
        </div>
        <div class="sliders">
            <input type="range" orient="vertical" v-model="volumeLoad" min="1" max="100">
            <span>Volume load</span>
            <span>{{ volumeLoad }} %</span>
        </div>
        <div class="fields-container">
            <div class="field">
                <span>Max trades per wallet</span>
                <div><input v-model="strategyConfig.maxSwapsPerWallet"></div>
            </div>
            <div class="field">
                <span>Spare wallets amount</span>
                <div><input v-model="strategyConfig.spareTradersAmount"></div>
            </div>
            <div class="field">
                <span>Spare wallets balance</span>
                <div><input v-model="strategyConfig.spareTradersBalance"></div>
            </div>
        </div>
        <div class="fields-container nested">
            <div class="fields-container">
                <div class="field">
                    <span>Ignore deviation high</span>
                    <div><input v-model="ignoreDeviationHigh"><span>%</span></div>
                </div>
                <div class="field">
                    <span>Ignore deviation low</span>
                    <div><input v-model="ignoreDeviationLow"><span>%</span></div>
                </div>
            </div>
        </div>
        <div class="fields-container nested">
            <div class="field">
                <span>Slippage</span>
                <div><input v-model="strategyConfig.slippage"><span>%</span></div>
            </div>
            <div class="fields-container">
                <div class="field">
                    <span>Gas price mode</span>
                    <select v-model="strategyConfig.gasModifiers.price.mode">
                        <option value="multiply">Multiply</option>
                        <option value="constant">Constant</option>
                    </select>
                </div>
                <div class="field">
                    <span>Gas price value</span>
                    <div class="long"><input v-model="strategyConfig.gasModifiers.price.value"></div>
                </div>
            </div>
            <div class="fields-container">
                <div class="field">
                    <span>Gas limit mode</span>
                    <select v-model="strategyConfig.gasModifiers.limit.mode">
                        <option value="multiply">Multiply</option>
                        <option value="constant">Constant</option>
                    </select>
                </div>
                <div class="field">
                    <span>Gas limit value</span>
                    <div class="long"><input v-model="strategyConfig.gasModifiers.limit.value"></div>
                </div>
            </div>
        </div>
        <button v-if="strategy.status !== 'Finished'" @click="updateStrategy">Save</button>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'SettingsComponent',
    emits: ['reload'],
    props: {
        strategy: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            slotsLoad: null,
            volumeLoad: null,
            ignoreDeviationHigh: null,
            ignoreDeviationLow: null,
            strategyConfig: {
                slippage: null,
                spareTradersAmount: null,
                spareTradersBalance: null,
                maxSwapsPerWallet: null,
                gasModifiers: { limit: { mode: null, value: null }, price: { mode: null, value: null }, }
            }
        }
    },
    mounted() {
        this.setInitValues()
    },
    methods: {
        setInitValues() {
            this.slotsLoad = parseInt(this.strategy.slots_load * 100);
            this.volumeLoad = parseInt(this.strategy.volume_load * 100);
            this.ignoreDeviationHigh = this.strategy.ignore_deviation_high * 100;
            this.ignoreDeviationLow = this.strategy.ignore_deviation_low * 100;
            this.strategyConfig = {
                slippage: this.strategy.config.slippage * 100,
                spareTradersAmount: this.strategy.config.spare_traders_amount,
                spareTradersBalance: this.strategy.config.spare_traders_balance / (10 ** 18),
                maxSwapsPerWallet: this.strategy.config.max_swaps_per_trader,
                gasModifiers: {
                    limit: {
                        mode: this.strategy.config.gas_modifiers.limit.mode,
                        value: this.strategy.config.gas_modifiers.limit.value,
                    },
                    price: {
                        mode: this.strategy.config.gas_modifiers.price.mode,
                        value: this.strategy.config.gas_modifiers.price.value,
                    },
                }
            }
        },
        async updateStrategy() {
            const payload = {
                ignore_deviation_low: this.ignoreDeviationLow / 100,
                ignore_deviation_high: this.ignoreDeviationHigh / 100,
                volume_load: this.volumeLoad / 100,
                slots_load: this.slotsLoad / 100,
                config: {
                    gas_modifiers: {
                        limit: this.strategyConfig.gasModifiers.limit,
                        price: this.strategyConfig.gasModifiers.price,
                    },
                    spare_traders_amount: this.strategyConfig.spareTradersAmount,
                    spare_traders_balance: this.strategyConfig.spareTradersBalance * (10 ** 18),
                    max_swaps_per_trader: this.strategyConfig.maxSwapsPerWallet,
                    slippage: this.strategyConfig.slippage / 100,
                },
            }
            try {
                const url = `pair/${this.strategy.pair_id}/strategies/${this.strategy.id}`
                await axios.put(url, payload)
                this.$root.displaySuccess('Success')
                this.$emit('reload')
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        }
    }
}
</script>
<style scoped>
.settings {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #CECECE;
    display: flex;
    font-size: 12px;
    gap: 20px;
    align-items: flex-start;
}
.settings.uneditable{
    pointer-events: none;
    opacity: 0.6;
}
.settings > button{
    width: 250px;
    font-size: 12px;
    padding: 7px;
    border-radius: 5px;
    background-color: #fae4ff;
}
.settings > button:hover{
    background-color: #ffbdf6;
}

.sliders {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sliders>input {
    appearance: slider-vertical;
    width: 30px
}

.fields-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.fields-container.nested {
    gap: 12px;
}

.field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 5px;
}

.field>div {
    width: 50px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.field>.long {
    width: 70px;
}

.field>div>input {
    width: 100%;
    border-radius: 5px;
    border-color: transparent;
    background-color: white;
    text-align: right;
    padding: 2px;
    font-size: 12px;
}

.field>select {
    border-radius: 5px;
    border-color: transparent;
    background-color: white;
    text-align: right;
    padding: 1px;
    font-size: 12px;
}
</style>