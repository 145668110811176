<template>
    <div class="delete-container">
        <div class="delete-btn-holder" v-if="deleteState === 0" @click="incrementState">
            <button class="delete-btn"></button>
        </div>

        <div v-if="deleteState === 1">
            <button @click="incrementState">
                Are you sure?
            </button>
            <button @click="cancelDelete">No</button>
        </div>

        <div v-if="deleteState === 2">
            <button @click="incrementState">
                Really? Sure?
            </button>
            <button @click="cancelDelete">No</button>
        </div>

        <div v-if="deleteState === 3">
            <input type="password" v-model="password" placeholder="Enter password" />
            <button @click="confirmDelete">Delete</button>
            <button @click="cancelDelete">No</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "DeleteButton",
    props: {
        pairId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            deleteState: 0,
            password: ''
        };
    },
    methods: {
        incrementState() {
            if (this.deleteState < 3) {
                this.deleteState++;
            }
        },
        async confirmDelete() {
            try {
                await axios.post('/pair/delete', {
                    pair_id: this.pair_id,
                    password: this.password
                });
                this.$router.push("/home");
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        cancelDelete() {
            this.deleteState = 0
        }
    }
};
</script>

<style scoped>
button {
    user-select: auto;
}

.delete-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.delete-btn-holder {
    display: flex;
    align-items: center;
}

.delete-btn {
    background: url('/public/icons/delete.svg');
    width: 20px;
    height: 20px;
    background-size: cover;
    border: none;
    background-color: none;
    background-repeat: no-repeat;
}

.delete-btn:hover {
    background-color: none;
}

input {
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 5px;
}
</style>