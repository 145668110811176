export function timeframeToSeconds(value) {
    const unit = value[value.length - 1];
    const amount = parseInt(value.slice(0, -1), 10);
    switch (unit) {
        case 'm':
            return amount * 60;
        case 'h':
            return amount * 60 * 60;
        default:
            return 0;
    }
}

export function findNextCandleOpenTime(timeframe) {
    const now = new Date();
    now.setSeconds(0, 0);

    const seconds = timeframeToSeconds(timeframe);

    const minutes = seconds / 60;
    const elapsedMinutes = now.getHours() * 60 + now.getMinutes();
    const t = Math.floor(elapsedMinutes / minutes);
    const nextTime = (t + 1) * minutes;
    const nextHour = Math.floor(nextTime / 60) % 24;
    const nextMinute = Math.floor(nextTime % 60);

    now.setHours(nextHour, nextMinute);
    return now;
}

export function calculatePoolChangesAmounts(tokensPooled, assetsPooled, targetPrice) {
    const invariant = tokensPooled * assetsPooled;
    const newTokensPooled = Math.sqrt(invariant / targetPrice);
    const newAssetsPooled = targetPrice * newTokensPooled;

    const tokensToSwap = newTokensPooled - tokensPooled;
    const assetsToSwap = newAssetsPooled - assetsPooled;

    return [tokensToSwap, assetsToSwap];
}