<template>
    <HeaderComponent />
    <div v-if="isLoaded" class="pair-page">
        <div v-if="pair.is_initialized" class="pair-page-content">
            <div class="pair-info">
                <div class="pair-info-left">
                    <span class="pair-info-symbol">{{ pair.base_token.symbol }} / {{ pair.quote_token.symbol }}</span>
                    <span class="pair-info-contract">{{ pair.contract }}</span>
                    <DeleteButton :pairId="pair.id" />
                </div>
                <div class="pair-info-right">
                    <img :src="require(`/public/img/swappers_logo/${pair.swapper.config.alias.front}.png`)" alt="Swapper logo" />
                    <img :src="require(`/public/img/networks_logo/${pair.swapper.network.config.alias.front}.png`)"
                        alt="Network logo" />
                </div>
            </div>
            <div class="pair-info">
                <div class="pair-info-left">
                    <span><b>{{ pair.base_token.symbol }}</b> ${{ roundToThreeSignificantDigits(usdPrices.base)
                        }}</span>
                    <span><b>{{ pair.quote_token.symbol }}</b> ${{ round(usdPrices.quote) }}</span>
                    <span><b>{{ pair.swapper.network.native_symbol }}</b> ${{ round(usdPrices.native) }}</span>
                </div>
                <div class="pair-info-right">
                    In pool
                    {{ round(reserves[0]) }}<b>{{ pair.base_token.symbol }}</b>
                    and
                    {{ round(reserves[1]) }}<b>{{ pair.quote_token.symbol }}</b>
                </div>
            </div>
            <div class="chart-container" v-if="pair">
                <iframe id="pair-chart" title="DEXTools Trading Chart" :src="chartUrl"></iframe>
            </div>
            <div class="tools-container">
                <div class="tool">
                    <StrategiesContainer :pair="pair" :reserves="reserves" />
                </div>
                <div class="tool">
                    <BalancesContainer :pair="pair" />
                </div>
                <div class="tool">
                    <TransactionsContainer :pair="pair" />
                </div>
                <div class="tool">
                    <QuickTrade :pair="pair" :reserves="reserves" />
                </div>
            </div>
            <div class="stats-container">
                <div class="stats">
                    <TotalBalance :pair="pair" :stats="stats.balances" />
                </div>
                <div class="stats">
                    <LiquidityPool :pair="pair" :stats="stats.lp" />
                </div>
                <div class="stats">
                    <TopHolders :pair="pair" :stats="stats.holders" />
                </div>
                <!-- <div class="stats">
                    <TradesData :pair="pair" />
                </div> -->
            </div>

        </div>
        <div v-else class="initialize-container">
            <InitializeContainer :pair="pair" @update-primary-wallet-native="updatePrimaryWalletNative" />
        </div>
    </div>
    <div v-else class="loader-holder">
        <div class="loader"></div>
    </div>
</template>

<script>
import axios from "axios";
import StrategiesContainer from "./Strategies/Strategies.vue";
import BalancesContainer from "./Balances/BalancesContainer.vue";
import InitializeContainer from "./InitializeContainer.vue";
import TransactionsContainer from "./Transactions/Transactions.vue";
import HeaderComponent from "../Header.vue";
import DeleteButton from "./DeleteButton.vue";
import QuickTrade from "./QuickTrade/QuickTrade.vue";
import TotalBalance from "./Statistics/TotalBalance.vue";
import LiquidityPool from "./Statistics/LiquidityPool.vue";
import TopHolders from "./Statistics/TopHolders.vue";
// import TradesData from "./Statistics/TradesData.vue";

export default {
    name: "PairPage",
    props: {
        pairId: {
            type: String,
            required: true,
        },
    },
    components: {
        StrategiesContainer,
        BalancesContainer,
        InitializeContainer,
        TransactionsContainer,
        HeaderComponent,
        DeleteButton,
        QuickTrade,
        TotalBalance,
        LiquidityPool,
        TopHolders,
        // TradesData
    },

    data() {
        return {
            pair: null,
            usdPrices: {
                native: null,
                base: null,
                quote: null
            },
            stats: {
                balances: null,
                holders: null,
                lp: null,
                trades: []
            },
            reserves: [null, null],
            isLoaded: false,
            autoRefreshTimer: null,
        };
    },
    async mounted() {
        await this.fetchPair();
        if (this.pair.is_initialized) {
            await this.fetchInfo();
            await this.fetchStats();

            clearInterval(this.autoRefreshTimer);
            this.autoRefreshTimer = setInterval(async () => {
                await this.fetchInfo();
            }, 20000);
        }

        this.isLoaded = true;
    },
    beforeUnmount() {
        clearInterval(this.autoRefreshTimer);
    },
    computed: {
        chartUrl() {
            return `https://www.dextools.io/widget-chart/en/${this.pair.swapper.network.config.alias.dextools}/pe-light/${this.pair.contract}?theme=dark&chartType=1&chartResolution=30&drawingToolbars=true`;
        },
    },
    methods: {
        round(value) {
            return parseInt(value * 1000) / 1000
        },
        roundToThreeSignificantDigits(num) {
            if (num === 0) return 0;
            const magnitude = Math.floor(Math.log10(Math.abs(num)));
            const rounded = Math.round(num / Math.pow(10, magnitude - 2)) * Math.pow(10, magnitude - 2);
            return rounded;
        },
        async fetchPair() {
            try {
                const url = `pair/${this.pairId}`
                const { data } = await axios.get(url)
                this.pair = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async fetchInfo() {
            try {
                const url = `pair/${this.pairId}/info`;
                const { data } = await axios.get(url)
                this.reserves = data.reserves;
                this.usdPrices = data.usd_prices;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async fetchStats() {
            try {
                const url = `pair/${this.pairId}/stats`;
                const { data } = await axios.get(url);
                this.stats = data;

            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        }
    },
};
</script>

<style>
.pair-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}

.pair-info-symbol {
    font-size: 24px;
}

.pair-info-contract {
    padding: 5px;
}

.chart-container {
    width: 100%;
    height: 600px;
}

#pair-chart {
    width: 100%;
    height: 100%;

}

.pair-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}

.pair-page-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pair-info-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.pair-info-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pair-info-right img {
    height: 45px;
}

.tools-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tools-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.tool {
    width: 350px;
    max-height: 500px;
    border: 1px solid black;
    padding: 20px;
    flex: 0 0 auto;
}

.tool-header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tool-header-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.stats-container {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.stats {
    padding: 20px;
    border: 1px solid black;
    width: 770px;
    flex: 0 0 auto;
}

.stats-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.stats-content {
    height: 400px;
    overflow: scroll;
}

.stats-chart {
    width: 100%;
    height: 100%;
}

.secret-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.secret-container>button {
    padding: 3px 6px;
    border-radius: 5px;
}

.secret-value {
    width: 300px;
    word-wrap: break-word;
    background-color: azure;
    padding: 10px;
    border-radius: 10px;
}
</style>
