<template>
    <div class="tool-container">
        <div class="tool-header">
            <div class="tool-header-title">Transactions</div>
            <div class="tool-header-buttons">
                <div class="autorefresh">
                    <span>Autorefresh</span>
                    <select v-model="autoRefresh.interval">
                        <option value="0">Off</option>
                        <option value="5000">5s</option>
                        <option value="10000">10s</option>
                        <option value="30000">30s</option>
                        <option value="60000">1m</option>
                    </select>
                </div>
                <button @click="refresh" :class="{ 'refreshing-circle': isRefreshing }"></button>
            </div>
        </div>
        <div class="transactions-container">
            <div v-if="!isFetching" class="transactions-list">
                <div v-for="txn in transactionsList" :key="txn.id">
                    <TransactionRow :transaction="txn" :explorerUrl="pair.swapper.network.explorer_url"
                        :pairId="pair.id" />
                </div>
            </div>
            <LoaderCirlce v-else />
            <div class="transaction-paginator">
                <div class="queued-tasks" v-if="queuedAmount">Pending: {{ queuedAmount }}</div>
                <div class="paginator">
                    <button :disabled="paginator.page <= 0" @click="switchPage(-1)">&larr;</button>
                    <span>{{ paginator.page + 1 }}</span>
                    <button :disabled="transactionsList.length != paginator.amount" @click="switchPage(1)">&rarr;</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import TransactionRow from './TransactionRow.vue'
import LoaderCirlce from '../../common/Loader.vue'

export default {
    name: "TransactionsContainer",
    props: {
        pair: {
            type: Object,
            required: true
        }
    },
    components: { TransactionRow, LoaderCirlce },
    data() {
        return {
            transactionsList: [],
            queuedAmount: null,
            paginator: {
                amount: 10,
                page: 0,
            },
            isFetching: false,
            isRefreshing: false,
            autoRefresh: {
                timer: null,
                interval: '0',
            }
        }
    },
    watch: {
        'autoRefresh.interval'(newInterval) {
            this.setAutorefresh(newInterval);
        }
    },
    async created() {
        await this.switchPage(0);
    },
    beforeUnmount() {
        clearInterval(this.autoRefresh.timer);
    },
    methods: {
        async fetchTransactions() {
            try {
                const url = `pair/${this.pair.id}/transactions`
                const { data } = await axios.get(url, {
                    params: {
                        offset: this.paginator.page * this.paginator.amount,
                        limit: this.paginator.amount,
                    }
                })
                this.transactionsList = data.transactions;
                this.queuedAmount = data.queued_amount;
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
        async refresh() {
            if (this.isRefreshing) {
                return
            }
            this.isRefreshing = true;
            await this.fetchTransactions()
            this.isRefreshing = false;
        },
        async switchPage(pageDelta) {
            this.isFetching = true;
            this.paginator.page += pageDelta;
            this.transactionsList = [];
            await this.fetchTransactions();
            this.isFetching = false;
        },
        setAutorefresh(interval) {
            clearInterval(this.autoRefresh.timer);
            if (interval > 0) {
                this.autoRefresh.timer = setInterval(() => {
                    this.refresh();
                }, interval);
            }
        }
    }
}
</script>
<style scoped>
.transactions-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 430px;
}
.transactions-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 5px;
}
.queued-tasks{
    font-size: 12px;
    position: absolute;
    padding: 3px 6px;
    border-radius: 5px;
    background-color: #eee;
    left: 1px;
}
.tool-header-buttons>button {
    background: url('/public/icons/refresh.svg');
    width: 20px;
    height: 20px;
    background-size: cover;
    border: none;
}

.transactions-load-more-btn {
    font-size: 12px;
    padding: 5px 0;
}
.transaction-paginator{
    margin-top: auto; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.paginator{
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.paginator > span{
    display: inline-block;
    width: 30px;
    text-align: center;
    padding: 3px 5px;
    background-color: #eee;
    border-radius: 10px;
}
.paginator > button:hover {
    background-color: transparent;
}
.autorefresh{
    display: flex;
    align-items: center;
}
.autorefresh > span{
    font-size:12px;
    margin-right: 4px;
}
.autorefresh > select{
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
}
</style>