<template lang="">
    <ModalWindow :title="'Trader'" @close="$emit('close')">
        <div class='modal-section'>
            <span class="modal-section-title">Address</span>
            <div> {{ trader.address }} </div>
            <button class="open-in-explorer-btn" @click="openInExplorer">Open in explorer</button>
        </div>
    
        <div class="modal-section">
            <span class="modal-section-title">Balances</span>
            <div class='balances-container'>
                <div class='balances-row'>
                    <span>
                        {{ balanceCorrector(trader.native_balance, 18) }}
                    </span>
                    <span>
                        {{ pair.swapper.network.native_symbol }}
                    </span>
                </div>
            </div>
        </div>
        <div class='modal-section'>
            <span class="modal-section-title">Trades amount</span>
            <span>{{ trader.trades_amount }}</span>
        </div>
        <div class="modal-footer">
            <button :disabled="waiting.blockchainRefresh" @click="blockchainRefresh">Refresh from blockchain</button>
        </div>
    </ModalWindow>
</template>
<script>
import axios from 'axios';
import ModalWindow from '../../common/Modal.vue'

export default {
    name: "ModalTrader",
    emits: ['close', 'refresh'],
    components: {ModalWindow},
    props: {
        pair: {
            type: Object,
            required: true,
        },
        trader: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            waiting: {
                blockchainRefresh: false,
            }
        }
    },
    methods: {
        balanceCorrector(value, decimals) {
            return Math.floor(parseFloat(value) / Math.pow(10, decimals) * 100000000) / 100000000;
        },
        openInExplorer() {
            const url = this.pair.swapper.network.explorer_url + 'address/' + this.trader.address;
            window.open(url, '_blank')
        },
        async blockchainRefresh() {
            if (this.waiting.blockchainRefresh) return;
            this.waiting.blockchainRefresh = true;

            try {
                const url = `pair/${this.pair.id}/trader/${this.trader.id}/refresh`
                const { data } = await axios.post(url);
                if (data.data) {
                    this.$root.displaySuccess('Successfully refreshed')
                    this.$emit('refresh')
                    return;
                } else {
                    this.$root.displaySuccess('Unable to fetch the result of execution');
                }
                
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.waiting.blockchainRefresh = false;
        },
    }
}
</script>
<style scoped>
.balances-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.balances-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.balances-row {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.modal-footer{
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.modal-footer > button,
.modal-footer > input{
    display: block;
    width: 100%;
    padding: 6px;
    border: 1px solid #bebebe;
}
.modal-footer > button:disabled,
.modal-footer > input:disabled{
    cursor: progress;
}
</style>