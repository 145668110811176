<template>
    <div class="trades-settings-container">
        <div class="trades-cell">
            <p>Ignore deviation high, %</p>
            <div>
                <input type="number" v-model="settings.ignoreDeviationHigh"/> 
            </div>
        </div>
        <div class="trades-cell">
            <p>Ignore deviation low, %</p>
            <div>
                <input type="number" v-model="settings.ignoreDeviationLow"/> 
            </div>
        </div>
        <div class="trades-cell">
            <p>Max trader swaps</p>
            <div>
                <input type="number" v-model="settings.maxWalletSwaps"/> 
            </div>
        </div>
        <div class="trades-cell">
            <p>New traders amount</p>
            <div>
                <input type="number" v-model="settings.newWalletsAmount"/> 
            </div>
        </div>
        <div class="trades-cell">
            <p>New traders balance</p>
            <div>
                <input type="number" v-model="settings.newWalletsBalane"/> 
            </div>
        </div>
        <div class="trades-cell">
            <p>Gas price</p>
            <div>
                <select v-model="settings.gasPrice.mode">
                    <option value="multiply">Multiply</option>
                    <option value="constant">Constant</option>
                </select> 
                <input type="number" v-model="settings.gasPrice.value"/> 
            </div>
        </div>
        <div class="trades-cell">
            <p>Gas limit</p>
            <div>
                <select v-model="settings.gasLimit.mode">
                    <option value="multiply">Multiply</option>
                    <option value="constant">Constant</option>
                </select> 
                <input type="number" v-model="settings.gasPrice.value"/> 
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TradersSettings",
    emits: ['configured'],
    data() {
        return {
            settings: {
                ignoreDeviationLow: 5,
                ignoreDeviationHigh: 5,
                maxWalletSwaps: 10,
                newWalletsAmount: 20,
                newWalletsBalane: 0.02,
                gasPrice: {
                    mode: 'multiply',
                    value: 1
                },
                gasLimit: {
                    mode: 'multiply',
                    value: 1
                },
            }
        }
    },
    computed: {
        config() {
            return {
                ignoreDeviationHigh: this.settings.ignoreDeviationHigh / 100,
                ignoreDeviationLow: this.settings.ignoreDeviationLow / 100,
                maxTraderSwaps: this.settings.maxWalletSwaps,
                newTradersAmount: this.settings.newWalletsAmount,
                newTradersBalance: this.settings.newWalletsBalane,
                gasModifiers: {
                    gasPrice: {
                        mode: this.settings.gasPrice.mode,
                        value: this.settings.gasPrice.value,
                    },
                    gasLimit: {
                        mode: this.settings.gasLimit.mode,
                        value: this.settings.gasLimit.value,
                    },
                }
            }
        }
    },  
    watch: {
        config(newVal) {
            this.$emit('configured', newVal)
        }
    },
    created() {
        this.$emit('configured', this.config)
    }
}
</script>
<style scoped>
.trades-settings-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.trades-cell{
    display: flex;
    align-items: center;
    border: 1px solid #bebebe;
    padding: 10px;
    border-radius: 10px;
    width: 270px;
    justify-content: space-between;
}
.trades-cell > p{
    font-size: 12px;
}
.trades-cell > div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}
.trades-cell > div > input,
.trades-cell > div > select{
    padding: 5px;
    border-radius: 5px;
}
.trades-cell > div > input{
    width: 70px;
}

</style>