<template>
    <div v-if="isLoaded" class="init-pair-message-holder">
        <div class="init-pair-message">
            <div class="init-pair-message-paragraph">
                Selected pair
                <b>{{ pair.base_token.symbol }}/{{ pair.quote_token.symbol }}</b>
            </div>
            <div class="init-pair-message-paragraph">You Primary Wallet address is:</div>
            <div class="init-pair-message-wallet">
                {{ balances.primary_wallet.address }}
            </div>
            <div class="init-pair-message-paragraph">
                Please, deposit this wallet to deploy Pair Manager contract.
            </div>
            <div class="balance-paragraph">
                <span>Current balance: </span>
                <span>
                    <b>{{ balanceCorrector(balances.primary_wallet.native_balance) }}</b>
                    {{ pair.network_symbol }}
                </span>
                <img @click="refreshPrimaryWallet" :class="{ 'refreshing-circle': isRefreshing }" id="refresh-btn" />
            </div>
            <div class="initialize-holder">
                <div v-if="!isInitializationSent" @click="initializePair" class="initialize-button">
                    Initialaize
                </div>
                <div v-else>
                    Initialization request was sent. Refresh this page is a minute.
                </div>
            </div>
            <div class="delete-holder">
                <button @click="deletePair">Delete</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "InitizalizeContainer",
    props: {
        pair: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoaded: false,
            isRefreshing: false,
            isInitializationSent: false,
            balances: {
                primary_wallet: null,
            },
        };
    },
    async mounted() {
        await this.fetchBalances();
        this.isLoaded = true;
    },
    methods: {
        balanceCorrector(value, decimals = 18) {
            const result = parseFloat(value) / Math.pow(10, decimals);
            return Math.round(result * 1000) / 1000;
        },
        async refreshPrimaryWallet() {
            if (this.isRefreshing) return;
            this.isRefreshing = true;

            try {
                const url = `pair/${this.pair.id}/primary-wallet/basic-refresh`;
                const { data } = await axios.post(url);
                this.balances.primary_wallet = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.isRefreshing = false;
        },
        async initializePair() {
            try {
                const url = `/pair/${this.pair.id}/initialize`;
                this.$root.displaySuccess('The request was send. Please wait around 60 seconds.');
                const { data } = await axios.post(url);
                if (data.data) {
                    location.reload();
                } else {
                    this.$root.displaySuccess('Unable to fetch execution result');
                }
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async deletePair() {
            try {
                const url = `/pair/${this.pair.id}`;
                await axios.delete(url);
                this.$router.push("/home");
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async fetchBalances() {
            try {
                const url = `/pair/${this.pair.id}/balances`;
                const { data } = await axios.get(url);
                this.balances = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
    },
};
</script>

<style scoped>
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

#refresh-btn {
    content: url("/public/icons/refresh.svg");
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-left: 10px;
}

#refresh-btn.is-spinning {
    cursor: not-allowed;
}

.init-pair-message-wallet {
    font-weight: 800;
}

.init-pair-message {
    margin-top: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: black;
}

.initialize-button {
    display: inline-block;
    background-color: aqua;
    padding: 10px 15px;
    cursor: pointer;
}

.initialize-button:hover {
    background-color: rgb(2, 157, 177);
}

.delete-holder>button:hover {
    background-color: rgb(230, 76, 105);
}
</style>
