<template>
    <ModalWindow :title="'New Trader'" @close="$emit('close')">
        <div>
            <div class='new-tw-container'>
                <div class="new-tw-row">
                    <span>
                        Initial Balance ({{ pair.swapper.network.native_symbol }})
                    </span>
                    <input type="number" v-model.number='initialBalance'>
                </div>
                <div class="new-tw-row">
                    <span>
                        Amount of Wallets
                    </span>
                    <input type="number" v-model.number='amount'>
                </div>
                <button @click="create" :class="{'sending': isSendingRequest}">Create</button>
            </div>
        </div>
    </ModalWindow>
</template>
<script>
import axios from "axios";
import ModalWindow from "../../common/Modal.vue"

export default {
    name: "ModalNewTrader",
    emits: ['close'],
    components: { ModalWindow },
    props: {
        pair: {
            type: Object,
            required: true
        },
        balances: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            initialBalance: 0,
            amount: 1,
            errorText: null,
            isSendingRequest: false,
        }
    },
    methods: {
        async create() {
            if (this.isSendingRequest) return;
            this.isSendingRequest = true;
            
            try {
                const url = `pair/${this.pair.id}/trader/create`
                const { data } = await axios.post(url, {
                    initial_balance: this.initialBalance * (10 ** 18),
                    wallets_amount: this.amount
                });
                if (data.data) {
                    this.$root.displaySuccess('Traders created successfully');
                } else {
                    this.$root.displaySuccess('Unable to fetch the result of execution');
                }
                this.close();
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.isSendingRequest = false;
        }
    }

}
</script>
<style scoped>
@keyframes changeBackgroundColor {
  0%, 100% {
    background-color: aqua;
  }
  50% {
    background-color: rgba(13, 203, 184, 0.491);
  }
}
button {
    background: none;
    border: none;
    font-size: 14px;
    font-family: 'Roboto Mono', monospace;
    border: 1px solid black;
    cursor: pointer;
    padding: 5px 0;
    width: 100%;
}
.sending{
    animation: changeBackgroundColor 2s linear infinite;
    border-color: transparent;
    cursor: not-allowed;
}


.new-tw-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.new-tw-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-tw-row>input {
    padding: 5px;
    font-family: 'Romoto Mono', monospace;
    max-width: 30%;
    font-size: 16px;
}

.new-tw-container>button:hover {
    background: aqua;
    border: 1px solid aqua;

}

.error {
    font-style: italic;
    font-size: 21;
    color: red;
}
</style>