<template>
    <div class="strategy-row" @click="isModalOpen = true">
        <div class="strategy-row-part">
            <span alt="Strategy icon" class="strategy-icon" :class="{
                basic: strategy.type == 'Basic',
                sequence: strategy.type == 'Sequence',
                composition: strategy.type == 'Composition',
            }"></span>
            <div>
                <span class="strategy-title">{{ strategy.title }}</span>
                <span class="strategy-timeframe">{{ strategy.timeframe }}</span>
            </div>
        </div>
        <div class="strategy-row-part">
            <span class="strategy-status" :class="{
                working: strategy.status === 'Working',
                paused: strategy.status === 'Paused',
                created: strategy.status === 'Created',
                finished: strategy.status === 'Finished',
                interrupted: strategy.status === 'Interrupted',
            }">{{ strategy.status }}</span>
        </div>
    </div>
    <StrategyModal v-if="isModalOpen" :strategyId="strategy.id" :strategyTitle="strategy.title" :pair="pair" :reserves="reserves" @close="isModalOpen=false" @updated="$emit('updated')"/>
</template>
<script>
import StrategyModal from "./StrategyModal/StrategyModal.vue";


export default {
    name: "StrategyRow",
    components: { StrategyModal },
    emits: ['updated'],
    props: {
        strategy: {
            type: Object,
            required: true
        },
        pair: {
            type: Object,
            required: true
        },
        reserves: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isModalOpen: false
        }
    },
}
</script>
<style scoped>
.strategy-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    height: 24px;
    padding: 5px;
    border-radius: 5px;
}

.strategy-row:hover {
    background-color: aliceblue;
}

.strategy-row-part {
    display: flex;
}

.strategy-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
    background-size: cover;
}

.strategy-icon.basic {
    background-image: url('/public/icons/strategy_types/basic.svg');
}

.strategy-icon.sequence {
    background-image: url('/public/icons/strategy_types/sequence.svg');
}

.strategy-icon.composition {
    background-image: url('/public/icons/strategy_types/composition.svg');
}

.strategy-timeframe {
    font-size: 12px;
    margin-left: 10px;
    color: #9e9e9e;
}
.strategy-status{
    padding: 3px 5px;
    border-radius: 5px;
}
.strategy-status.working{
    background-color: rgb(189, 252, 189);
}
.strategy-status.paused{
    background-color: rgb(218, 218, 218);
}
.strategy-status.created{
    padding: 2px 3px;
    border: 1px solid black;
}
.strategy-status.finished{
    background-color: rgb(179, 245, 255);
}
.strategy-status.interrupted{
    background-color: rgb(243, 181, 235);
}

</style>