<template>
    <div id="app">
        <div v-if="!pinged" class="loader-holder">
            <div class="loader"></div>
        </div>
        <router-view v-else></router-view>
        <div class="messages">
            <div v-for="(message, index) in messages" :key="index" class="message"
                :class="{ 'error': message.type === 'error', 'success': message.type === 'success' }">
                {{ message.text }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "App",
    data() {
        return {
            pinged: false,
            messages: [],
        }
    },
    async created() {
        await this.ping()
    },
    methods: {
        async captchaToken(action) {
            try {
                const token = await this.$recaptcha(action);
                if (token) {
                    return token
                }
            } catch (error) {
                this.displayError('reCAPTCHA verification failed');
            }
        },
        displayMessage(message) {
            this.messages.push(message);
            setTimeout(() => {
                this.messages.splice(this.messages.indexOf(message), 1);
            }, 5000);
        },
        displaySuccess(text) {
            this.displayMessage({ text: text, type: "success"})
        },
        displayError(text) {
            this.displayMessage({ text: text, type: "error"})
        },
        
        async ping() {
            try {
                await axios.get("public/ping");
                this.pinged = true;
            } catch (err) {
                this.displayError(err.response.data.detail)
            }
        }
    },
};
</script>

<style>
body {
    font-family: "Roboto Mono", monospace;
    margin: 0;
}

input {
    font-family: 'Roboto Mono', monospace;
    background: none;
    border: 1px solid black;
}

input,
select {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

button {
    background: none;
    border: none;
    font-family: 'Roboto Mono', monospace;
    cursor: pointer;
}

button:hover {
    background-color: rgba(0, 0, 0, 0.10);
}
button:disabled:hover{
    cursor: not-allowed;
}

*:focus {
    outline: none;
}

select {
    background: none;
    border: none;
    font-family: 'Roboto Mono', monospace;
    border: 1px solid black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}
p {
    margin: 0;
    display: inline;
}

.modal-window-header:first-child {
    font-size: 20px;
    font-weight: 800;
}

.modal-close {
    color: #aaa;
    text-align: right;
    font-size: 28px;
    font-weight: bold;
}

.messages {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 200;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.message {
    color: white;
    padding: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.message.error {
    background: linear-gradient(96.43deg, #b5453d 0%, #C92B2B 100%);
}

.message.success {
    background: linear-gradient(96.43deg, #3db071 0%, #0FA770 100%);
}

.refreshing-circle {
    cursor: not-allowed;
    animation: rotate 1s linear infinite;
    opacity: 0.5;
}
.cs {
    width: 550px;
}

.cs>* {
    font-family: 'Oxygen';
}

.cs-header {
    width: 100%;
    padding-bottom: 7px;
    border-bottom: 2px solid #D9D9D9;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: 'Oxygen';
}
.cs-header.small {
    font-size: 18px;
    border-width: 1.5px;
}

.cs-content>span {
    margin-bottom: 20px;
    display: block;
}

.cs-input,
.cs-button,
.cs-select {
    border-radius: 12px;
    font-family: 'Oxygen' !important;
    font-size: 16px;
    font-weight: 700;
    border: none;
    padding: 7px 17px;
    border: 2px solid #eee;
    box-sizing: border-box;
    font-family: 'Oxygen';
}
.cs-input.small,
.cs-button.small,
.cs-select.small{
    border-width: 1px;
    font-weight: 700;
    padding: 6px 14px;
    font-size: 14px;
    min-width: 70px;
}

.cs-input, .cs-select {
    background-color: #eee;
}

.cs-input::placeholder {
    color: #A0A0A0
}

.cs-input.non-empty,
.cs-select.non-empty {
    background-color: transparent;
}

.cs-button {
    min-width: 110px;
    background-color: transparent;
    color: #A0A0A0;
    border-color: #A0A0A0
}

.cs-button:hover {
    color: black;
    border-color: black;
    background-color: transparent !important;
}

.cs-button.next {
    border-color: #0030DC;
    color: #0030DC;
}
.cs-select{
    color: #A0A0A0;
}
.cs-select.non-empty{
    color: black;
}

.cs-button:disabled {
    border-color: #eee;
    color: #a0a0a0;
}
</style>