<template>
    <div class="cs">
        <div class="cs-header">
            Enter your Invitation token
        </div>
        <div class="cs-content">
            <span>
                It can only be issued by our sales office.
                If you don't have it and want to start using Bitfusion, email us at <b>sales@bitfusion.trade</b>.
            </span>
            <div class="cs-tools">
                <input class="cs-input" v-model="invitationToken" :class="{ 'non-empty': invitationToken.length > 0 }"
                    placeholder="Put your token here">
                <button class="cs-button next" :disabled="invitationToken.length === 0"
                    @click="checkInvitationToken">Next</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "InvitationToken",
    emits: ['updContext', 'next'],
    data() {
        return {
            invitationToken: "",
            recaptchaToken: null,
        }
    },
    async mounted() {
        await this.$recaptchaLoaded()
  },
    methods: {
        async checkInvitationToken() {
            const recaptchaToken = await this.$root.captchaToken('invitationCheckToken');
            try {
                const url = `registration/check-invitation`;
                const { status } = await axios.post(url, {
                    invitation_token: this.invitationToken,
                    recaptcha: recaptchaToken
                }, { withCredentials: true })
                if (status === 200) {
                    this.$emit('updContext', {})
                    this.$emit('next')
                }
            } catch (error) {
                if (error.response.status === 404) {
                    this.$root.displayError("Registration token is not found")
                    return
                }
                this.$root.displayError(error.response.data.detail)
            }
        },
    },
}
</script>
<style scoped>
.cs-tools {
    display: flex;
    justify-content: space-between;
}

.cs-tools>input {
    width: 300px;
    margin-left: -2px;
}
</style>