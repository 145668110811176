<template>
    <div class="cs">
        <div class="cs-header">
            Verify email
        </div>
        <div class="cs-content">
            <span>
                Enter the code sent to the <b>{{ context.username }}</b>
            </span>
            <div class="cs-tools">
                <input class="cs-input" v-model="code" placeholder="Received code" :class="{ 'non-empty': code.length > 0 }">
                <div class="resend-container">
                    <span v-if="resendWait > 0">{{ resendWait }}</span>
                    <button class="cs-button" :disabled="resendWait > 0" @click="resend">Resend</button>
                </div>
            </div>
            <div class="cs-tools">
                <button class="cs-button next" :disabled="!code" @click="verify">Verify</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "TwoFactor",
    emits: ['upd-context'],
    props: {
        context: {
            type: Object
        }
    },
    data() {
        return {
            code: "",
            resendWait: this.context.resendWait,
            timer: null, 
        }
    },
    async mounted() {
        this.startTimer();
    },
    beforeUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        async resend() {
            try {
                const url = `auth/twofa/resend`;
                const { data } = await axios.post(url, {old_twofa_id: this.context.twofaId})
                this.$emit('upd-context', {twofaId: data.twofa_id, resendWait: data.resend_wait})
                this.resendWait = this.context.resendWait;
                this.startTimer();
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async verify() {
            try {
                const url = `auth/twofa`;
                const {data} = await axios.post(url, {twofa_id: this.context.twofaId, code: this.code}, { withCredentials: true })
                axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
                this.$router.push("/home");
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        startTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.resendWait > 0) {
                    this.resendWait--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        }
    },
}
</script>
<style scoped>
.cs-tools {
    display: flex;
    justify-content: space-between;
}

.cs-tools>input {
    width: 300px;
    margin-left: -2px;
}
.resend-container{
    display: flex;
    gap: 20px;
    align-items: center
}
.resend-container > span{
    color: #a0a0a0;
}
.cs-content > :last-child{
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse
}
</style>