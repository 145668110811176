import axios from 'axios';
import router from '@/router/index';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

let refresh = false;
let loginURL = axios.defaults.baseURL + '/auth';

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 503) {
        await router.push("/503");
        return;   
    }
    if (error.response.status === 500) {
        await router.push("/500");
        return;   
    }
    if ((error.response.status === 401) && !refresh && (error.request.responseURL !== loginURL)) {
        refresh = true;
        try {
            const { data } = await axios.post('auth/new-access-token', {}, {
                withCredentials: true
            });
            axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
            error.config.headers['Authorization'] = `Bearer ${data.access_token}`;
            refresh = false;
            return axios(error.config);
        } catch (error) {
            refresh = false;
            if (error.response.status === 401) {
                await router.push("/login");
            }
        }
    }
    refresh = false;
    throw error;
});