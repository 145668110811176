<template>
    <ModalWindow @close="$emit('close')" :title="'Member'">
        <div>
            <div class='modal-section'>
                <span class="modal-section-title">Name</span>
                <div>{{ member.first_name }} {{ member.last_name }}</div>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Email</span>
                <div>{{ member.email }}</div>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Access Level</span>
                <select v-model="edits.accessLevel">
                    <option value="3">Admin</option>
                    <option value="2">Manager</option>
                    <option value="1">User</option>
                </select>
                <button :disabled="isPosting" class="update-btn" v-if="accessLevelChanged" @click="updateAccessLevel">Update access level</button>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Blocked</span>
                <select v-model="edits.blocked">
                    <option :value="true">True</option>
                    <option :value="false">False</option>
                </select>
                <button :disabled="isPosting" class="update-btn" v-if="blockedChanged" @click="updateBlocked">Update</button>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Actions</span>
                <div class="actions">
                    <button :disabled="isPosting" @click="resetPassword">Reset password</button>
                    <button :disabled="isPosting" @click="deleteMember">Delete</button>
                </div>
            </div>
        </div>
    </ModalWindow>
</template>
<script>
import axios from 'axios'
import ModalWindow from '../../common/Modal.vue'

export default {
    name: 'MemberEditModal',
    components: { ModalWindow },
    emits: ['close', 'refresh'],
    props: {
        member: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            accessLevels: {
                3: 'Admin',
                2: 'Manager',
                1: 'User',
            },
            isPosting: false,
            edits: {
                accessLevel: this.member.access_level,
                blocked: this.member.is_disabled,
            }
        }
    },
    computed: {
        accessLevelChanged() {
            return (this.edits.accessLevel !== this.member.access_level)
        },
        blockedChanged() {
            return (this.edits.blocked !== this.member.is_disabled)
        }
    },
    methods: {
        async resetPassword() {
            this.isPosting = true;
            try {
                const url = `organization/members/${this.member.id}/reset-password`
                const { data } = await axios.post(url)
                this.$root.displaySuccess(`New password: ${data.password}`)
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
            this.isPosting = false;
        },
        async updateAccessLevel() {
            this.isPosting = true;
            try {
                const url = `organization/members/${this.member.id}/change-access-level`
                await axios.post(url, {new_access_level: this.edits.accessLevel})
                this.$root.displaySuccess("Success")
                this.$emit('refresh')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
            this.isPosting = false;
        },
        async updateBlocked() {
            this.isPosting = true;
            try {
                const action = this.edits.blocked ? "disable" : "enable"
                const url = `organization/members/${this.member.id}/${action}`
                await axios.post(url, {new_access_level: this.edits.accessLevel})
                this.$root.displaySuccess(`User ${action}d`)
                this.$emit('refresh')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
            this.isPosting = false;
        },
        async deleteMember() {
            this.isPosting = true;
            try {
                const url = `organization/members/${this.member.id}`
                await axios.delete(url)
                this.$root.displaySuccess("User deleted")
                this.$emit('refresh')
                this.$emit('close')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
            this.isPosting = false;
        }
    },
}
</script>
<style scoped>
select, input{
    padding: 3px 6px;
    border-radius: 5px;
    border-color: #bebebe;
}
.update-btn {
    margin-left: 10px;
}

.actions {
    display: flex;
    gap: 10px;
}

.actions>button {
    padding: 3px 6px;
    background-color: #eee;
}
</style>