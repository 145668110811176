<template>
    <div class='transaction-row' :class="{
        send: transaction.status === 'Send',
        success: transaction.status === 'Success',
        fail: transaction.status === 'Fail',
        notexecuted: transaction.status === 'NotExecuted'
    }">
        <div class="transaction-left-part" @click="isModalOpen = true">
            <span class="transaction-type" :title="transaction.type">
                {{ transaction.type }}
            </span>
            <span class="transaction-hash">
                {{ shortHash() }}
            </span>
            <button v-if="transaction.hash" class="transaction-view-in-explorer-btn" @click.stop="openInExporer()">
                🔎
            </button>
        </div>
        <div class="transaction-right-part">
            <span class="transaction-created-at">
                {{ shortCreatedAt() }}
            </span>
        </div>
    </div>
    <TransactionModal v-if="isModalOpen"
    :transactionId="transaction.id"
    :pairId="pairId"
    :explorerUrl="explorerUrl"
    @close="isModalOpen = false"
    />
</template>
<script>
import TransactionModal from './TransactionModal.vue';

export default {
    name: "TransactionRow",
    components: { TransactionModal},
    props: {
        transaction: {
            type: Object,
            required: true
        },
        pairId: {
            type: String,
            required: true
        },
        explorerUrl: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            isModalOpen: false,
        }
    },
    methods: {
        shortHash() {
            if (this.transaction.hash) {
                return ".." + this.transaction.hash.substr(-4);
            }
            return null;
        },
        shortCreatedAt() {
            const createdAt = new Date(this.transaction.created_at);
            const today = new Date();
            const [dateStr, timeStr] = [createdAt.toLocaleDateString(), createdAt.toLocaleTimeString()];

            if (
                createdAt.getDate() === today.getDate() &&
                createdAt.getMonth() === today.getMonth() &&
                createdAt.getFullYear() === today.getFullYear()
            ) return createdAt.toLocaleTimeString();
            return `${dateStr.slice(0, -5)} ${timeStr}`;
        },
        openInExporer() {
            const url = this.explorerUrl + 'tx/' + this.transaction.hash;
            window.open(url, '_blank')
        },
    }
}
</script>
<style>
.transaction-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    height: 24px;
    padding: 5px;
    border-radius: 5px;
}

.transaction-row.send {
    background-color: rgb(255, 255, 220);
}

.transaction-row.success {
    background-color: rgb(225, 252, 231);
}

.transaction-row.fail {
    background-color: rgb(255, 226, 226);
}

.transaction-row.notexecuted {
    background-color: rgb(224, 224, 224);
}

.transaction-left-part,
.transaction-right-part {
    display: flex;
    align-items: center;
}

.transaction-type {
    padding: 3px 5px;
    border: 1px solid rgb(78, 78, 78);
    border-radius: 5px;
    margin-right: 5px;
    width: 100px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.transaction-type:hover {
    cursor: pointer;
    font-weight: 700;
}

.transaction-created-at {
    font-style: italic;
}

.transaction-view-in-explorer-btn:hover {
    background-color: transparent;
}
</style>