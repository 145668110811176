<template>
    <div class="stats-header">
        <span>Top Holders</span>
    </div>
    <div class="stats-content">
        <div v-if="!stats" class="loader-holder">
            <div class="loader"></div>
        </div>
        <div class="stats-chart">
            <div>
                <canvas id="topHoldersChart"></canvas>
            </div>
        </div>
    </div>
</template>
  
<script>
import Chart from 'chart.js/auto'

export default {
    name: "TopHolders",
    props: {
        pair: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            default: null
        }
    },
    mounted() {
        if (this.stats) {
            this.drawChart();
        }
    },
    methods: {
        shortenAddress(address) {
            if (address !== 'Others') {
                return address.slice(0, 3) + ".." + address.slice(-3);
            } else return address;

        },
        generateChartData() {
            const balances = this.stats.value.holders.map(holder => holder.balance / (10 ** this.pair.base_token.decimals));
            const totalTopHoldersBalance = balances.reduce((total, balance) => total + balance, 0);
            const othersBalance = this.stats.value.totalSupply / (10 ** this.pair.base_token.decimals) - totalTopHoldersBalance;
            const allBalance = [...balances, othersBalance]
            const fullAddresses = [...this.stats.value.holders.map(holder => holder.address), 'Others'];
            const shortAddresses = fullAddresses.map(this.shortenAddress);

            const colors = this.generateColors(allBalance.length)

            return {
                fullAddresses: fullAddresses,
                chartData: {
                    labels: shortAddresses,
                    datasets: [{ data: allBalance, backgroundColor: colors }],
                },
            }
        },
        generateColors(steps) {
            const colors = [];
            const startColor = { r: 249, g: 132, b: 143 };
            const endColor = { r: 70, g: 16, b: 117 };
            for (let i = 0; i < steps; i++) {
                const ratio = i / (steps - 1);
                const red = Math.round(startColor.r + (endColor.r - startColor.r) * ratio);
                const green = Math.round(startColor.g + (endColor.g - startColor.g) * ratio);
                const blue = Math.round(startColor.b + (endColor.b - startColor.b) * ratio);
                colors.push(`rgb(${red}, ${green}, ${blue})`);
            }
            return colors;
        },
        drawChart() {
            const data = this.generateChartData()

            const location = document.getElementById('topHoldersChart');
            location.height = data.fullAddresses.length * 10;
            const ctx = location.getContext('2d');

            new Chart(ctx, {
                type: 'bar',
                data: data.chartData,
                options: {
                    indexAxis: 'y',
                    plugins: {
                        legend: { display: false },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                title: (context) => {
                                    const index = context[0].dataIndex;
                                    return data.fullAddresses[index];
                                }
                            },
                        }
                    },
                    onClick: (event, elements) => {
                        if (elements.length > 0) {
                            const index = elements[0].index;
                            const address = data.fullAddresses[index];
                            if (address === 'Others') return;
                            const url = `${this.pair.swapper.network.explorer_url}/address/${address}`;
                            window.open(url, '_blank');
                        }
                    }
                },
            });
        }
    },
}

</script>
  
<style scoped></style>  