<template>
    <div class="loader-holder">
        <div class="loader"></div>
    </div>
</template>
<script>
export default {
    name: "LoaderCircle",
}
</script>
<style>
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spinLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid aqua;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spinLoader 2s linear infinite;
}

.loader-small {
    border: 3px solid #f3f3f3;
    border-top: 3px solid aqua;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spinLoader 2s linear infinite;
    display: inline-block;
    vertical-align: middle;
}

</style>