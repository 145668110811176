<template>
    <div class="panel-body">
        <label class="switch">
            <input type="checkbox" @click="toggleCheckbox">
            <div class="slider round"></div>
        </label>
    </div>
</template>
<script>
export default {
    name: "ToggleSwitch",
    emits: ['newVal'],
    data() {
        return {
            checkbox: false
        }
    },
    methods: {
        toggleCheckbox() {
            this.checkbox = !this.checkbox
            this.$emit('newVal', this.checkbox)
        }
    }
}
</script>
<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19.1px;
    width: 19.1px;
    left: 3px;
    bottom: 2.7px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #19d5ea;
}

input:focus+.slider {
    box-shadow: 0 0 1px #19d5ea;
}

input:checked+.slider:before {
    -webkit-transform: translateX(19.1px);
    -ms-transform: translateX(19.1px);
    transform: translateX(19.1px);
}

.slider.round {
    border-radius: 25px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>