<template>
    <div class="header-container">
        <div class="header-logo">
            BITFUSION
        </div>
        <div class="header-right">
            <span id="utc-time"> {{ currentTime }}</span>
            <button class="cs-button small" @click="$router.push('/home')">Home</button>
            <button class="cs-button small" @click="$router.push('/organization')">Organization</button>
            <button class="cs-button small" @click="$router.push('/profile')">Profile</button>
            <button class="cs-button small" @click="logout" id="logout-btn">Logout</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "HeaderComponent",
    data() {
        return {
            currentTime: "",
        }
    },
    mounted() {
        this.updateTime();
        setInterval(this.updateTime, 1000);
    },
    methods: {
        async logout() {
            try {
                await axios.post('/user/logout')
                this.$router.push("/login");
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            
        },
        updateTime() {
            const now = new Date();
            const hours = now.getUTCHours().toString().padStart(2, "0");
            const minutes = now.getUTCMinutes().toString().padStart(2, "0");
            const seconds = now.getUTCSeconds().toString().padStart(2, "0");
            this.currentTime = `${hours}:${minutes}:${seconds} UTC`;
        },
    }
}
</script>

<style scoped>
.header-logo {
    font-family: 'Bruno Ace SC', cursive;
    font-size: 20px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 5%;
    margin-bottom: 20px;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

#utc-time {
    font-size: 14px;
    opacity: 0.5;
    margin-right: 5px;
}
#logout-btn{
    margin-left: 10px;
    opacity: 0.4;
}
#logout-btn:hover{
    opacity: 1;
}
</style>